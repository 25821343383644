import React from 'react';
import {
  Input,
  Button,
  useAlert,
  Checker
} from '@uxparaminaspretas/components';
import { useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useForm from 'hooks/useForm';

import AuthNavbar from 'components/AuthNavbar';
import { resetPassword } from 'services/authenticate';
import { PasswordDisclaimers } from 'pages/SignUp/styles';
import queryString from 'query-string';
import { ResetForgotPasswordForm, Title, Description, Fields } from './styles';

const ResetForgotPasswordSchema = yup.object().shape({
  document: yup.string().required().length(11),
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'As senhas devem ter o mesmo valor.')
});

const passwordRules = (password: string): boolean[] => [
  password.length >= 8,
  /[A-Z]/.test(password),
  /[a-z]/.test(password),
  /\d/.test(password),
  /[^0-9a-zA-Z]/.test(password)
];

const ResetForgotPassword: React.FC = () => {
  const alert = useAlert();
  const history = useHistory();

  const { fields, setField, errors, handleSubmit, loading } = useForm(
    { document: '', password: '', confirmPassword: '' },
    ResetForgotPasswordSchema,
    async (fields) => {
      const isPasswordValid = passwordRules(fields.password).every(
        (rule) => rule
      );

      if (!isPasswordValid) {
        return;
      }

      // eslint-disable-next-line no-restricted-globals
      const queryParameters = queryString.parse(location.search);

      const result = await resetPassword(
        fields.document.replace(/[^\d]/g, ''),
        fields.password,
        queryParameters?.code
          ? queryParameters.code.toString()
          : 'NAO ENCONTRADO'
      );

      if (result.statusCode === 200) {
        alert.success('Sua senha foi redefinida com sucesso!');
        history.push('/');
      } else {
        alert.error(
          'O código utilizado não é válido ou não encontramos uma solicitação para reset de senha para esse documento.'
        );
      }
    }
  );

  return (
    <AuthNavbar>
      <ResetForgotPasswordForm
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Title textAlign="center" level={1}>
          Redefinir senha
        </Title>
        <Description>Insira a sua nova senha abaixo.</Description>
        <Fields>
          <Input
            name="document"
            label="CPF"
            onChange={(e) =>
              setField('document', e.target.value.replace(/[^\d]/g, ''))
            }
            placeholder="000.000.000-00"
            mask={[
              { length: 3, regexp: /^[0-9]{1,3}$/ },
              { fixed: '.' },
              { length: 3, regexp: /^[0-9]{1,3}$/ },
              { fixed: '.' },
              { length: 3, regexp: /^[0-9]{1,3}$/ },
              { fixed: '-' },
              { length: 2, regexp: /^[0-9]{1,2}$/ }
            ]}
            {...errors.document}
          />
          <div>
            <Input
              name="password"
              type="password"
              label="Senha"
              value={fields.password}
              onChange={(e) => setField('password', e.target.value)}
              {...errors.password}
            />
            <PasswordDisclaimers>
              <Checker
                messages={[
                  'Deve ter no mínimo 8 caracteres',
                  'Ao menos uma letra maiúscula',
                  'Ao menos uma letra minúscula',
                  'Ao menos um número',
                  'Ao menos um caractere especial'
                ]}
                rules={fields.password ? passwordRules(fields.password) : []}
              />
            </PasswordDisclaimers>
          </div>
          <Input
            name="confirmPassword"
            label="Confirmar senha"
            type="password"
            value={fields.confirmPassword}
            onChange={(e) => setField('confirmPassword', e.target.value)}
            {...errors.confirmPassword}
          />
          <Button
            loading={loading}
            type="submit"
            primary
            label="Redefinir Senha"
          />
        </Fields>
      </ResetForgotPasswordForm>
    </AuthNavbar>
  );
};

export default ResetForgotPassword;
