import styled from 'styled-components';

export const Content = styled.div`
  display: block;

  @media only screen and (max-width: 768px) {
    display: none;
  }
`;

export const MobileContent = styled.div`
  display: none;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;
