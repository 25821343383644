import React from 'react';
import {
  Divider,
  Heading,
  Link,
  Subtitle
} from '@uxparaminaspretas/components';
import facebookIcon from 'assets/facebook-icon.svg';
import linkedinIcon from 'assets/linkedin-icon.svg';
import instagramIcon from 'assets/instagram-icon.svg';
import telegramIcon from 'assets/telegram-icon.svg';
import { jsPDF as JsPDF } from 'jspdf';
import { useRegisterForm } from 'provider/RegisterFormProvider';
import html2canvas from 'html2canvas';
import { differenceInYears, parse } from 'date-fns';
import {
  DownloadSection,
  PersonalInfoSection,
  SocialMediaContainer,
  HeadingContainer,
  HeadingStyled,
  UxExperienceSection,
  WorkPeriodContainer,
  ProfessionalExperienceSection,
  EducationSection,
  CustomParagraph,
  SubtitleStyled,
  ParagraphStyled
} from './styles';

const MyProfile: React.FC = () => {
  const { fields } = useRegisterForm();

  const calculateAge = (): string => {
    if (fields.birthDate) {
      const date = parse(fields?.birthDate, 'yyyy-MM-dd', new Date());
      const age = differenceInYears(new Date(), date);
      return `${age} anos,`;
    }
    return '';
  };

  const generatePdf = (): any => {
    const htmlSource = document.getElementById('profile');
    const filename = `Currículo ${fields.name}`;

    if (!htmlSource) {
      return;
    }

    html2canvas(htmlSource).then(function (canvas) {
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 180;
      const pageHeight = 297;

      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      let heightLeft = imgHeight;
      let position = 5;
      const pdf = new JsPDF('p', 'mm');

      pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
      heightLeft -= pageHeight;

      while (heightLeft >= 0) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight);
        heightLeft -= pageHeight;
      }
      pdf.save(filename);
    });
  };

  return (
    <>
      <DownloadSection>
        <Link
          onClick={() => generatePdf()}
          icon="file_download"
          label="Baixar em PDF"
        />
      </DownloadSection>
      <div id="profile">
        <PersonalInfoSection>
          <HeadingContainer>
            <Heading level={2}>{fields.name}</Heading>
            <HeadingStyled level={3}>
              <span>{calculateAge()}</span> <span>{fields.address?.city}</span>
            </HeadingStyled>
            <SocialMediaContainer>
              {fields.socialMedia?.facebook && (
                <a
                  href={fields.socialMedia.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={facebookIcon} alt="Ícone do Facebook" />
                </a>
              )}

              {fields.socialMedia?.linkedin && (
                <a
                  href={fields.socialMedia.linkedin}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={linkedinIcon} alt="Ícone do Linkedin" />
                </a>
              )}

              {fields.socialMedia?.instagram && (
                <a
                  href={fields.socialMedia.instagram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={instagramIcon} alt="Ícone do Instagram" />
                </a>
              )}

              {fields.socialMedia?.telegram && (
                <a
                  href={fields.socialMedia.telegram}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img src={telegramIcon} alt="Ícone do Telegram" />
                </a>
              )}
            </SocialMediaContainer>
          </HeadingContainer>
        </PersonalInfoSection>
        <SubtitleStyled large>Resumo sobre mim</SubtitleStyled>
        <ParagraphStyled>
          {fields.professionalExperience?.summary}
        </ParagraphStyled>
        <Divider size={1} margin={40} />
        {fields.education?.institutions?.length && (
          <>
            <SubtitleStyled large>Formação acadêmica</SubtitleStyled>
            <EducationSection>
              {fields.education?.institutions?.map((item, index) => (
                <div className="educational" key={index}>
                  <CustomParagraph>{item.course}</CustomParagraph>
                  <ParagraphStyled>{item.name}</ParagraphStyled>
                  <ParagraphStyled>
                    {item.yearStartDate} - {item.yearEndDate}
                  </ParagraphStyled>
                </div>
              ))}
            </EducationSection>
            <Divider size={1} margin={40} />
          </>
        )}

        {fields.professionalExperience?.companies?.length && (
          <>
            <SubtitleStyled large>Experiência profissional</SubtitleStyled>
            <>
              {fields.professionalExperience?.companies?.map((item, index) => (
                <ProfessionalExperienceSection
                  className="professional"
                  key={index}
                >
                  <Subtitle>Nome da Empresa</Subtitle>
                  <ParagraphStyled>{item.name}</ParagraphStyled>
                  <Subtitle>Cargo</Subtitle>
                  <ParagraphStyled>{item.position}</ParagraphStyled>
                  <WorkPeriodContainer>
                    <div>
                      <Subtitle>Data de início</Subtitle>
                      <ParagraphStyled>{`${
                        item.monthStartDate?.toString().padStart(2, '0') || '-'
                      }/${item.yearStartDate || '-'}`}</ParagraphStyled>
                    </div>

                    <div>
                      <Subtitle>Data de término</Subtitle>
                      <ParagraphStyled>
                        {item.current
                          ? '-'
                          : `${
                              item.monthEndDate?.toString().padStart(2, '0') ||
                              '-'
                            }/${item.yearEndDate || '-'}`}
                      </ParagraphStyled>
                    </div>
                  </WorkPeriodContainer>
                  {item.activities && (
                    <ParagraphStyled>{item.activities}</ParagraphStyled>
                  )}
                </ProfessionalExperienceSection>
              ))}
            </>
            <Divider size={1} margin={40} />
          </>
        )}
        <UxExperienceSection>
          {fields.uxExperience?.skills.length && (
            <div>
              <Subtitle>Softskills</Subtitle>
              {fields.uxExperience?.skills?.map((item, index) => (
                <ParagraphStyled key={index}>{item}</ParagraphStyled>
              ))}
            </div>
          )}

          {fields.uxExperience?.portfolio && (
            <div>
              <Subtitle>Portfólio</Subtitle>
              <ParagraphStyled>
                {fields.uxExperience?.portfolio}
              </ParagraphStyled>
            </div>
          )}
        </UxExperienceSection>
      </div>
    </>
  );
};

export default MyProfile;
