import React from 'react';
import { Row, Col, Textarea, Divider } from '@uxparaminaspretas/components';

import { useRegisterForm } from 'provider/RegisterFormProvider';
import Company from 'models/Company';

import CompanyForm from './CompanyForm';
import { Error, LinkNewCompany } from './styles';

const ProfessionalExperience: React.FC = () => {
  const { fields, errors, setField, setArrayField } = useRegisterForm();

  return (
    <>
      <Row>
        <Col xs={12}>
          <Textarea
            label="Faça um resumo sobre sua carreira"
            placeholder="Conta pra gente suas experiências profissionais, em qual área já atuou e o que está buscando"
            value={fields.professionalExperience?.summary}
            onChange={(e) =>
              setField('professionalExperience.summary', e.target.value)
            }
            {...errors['professionalExperience.summary']}
          />
        </Col>
      </Row>
      <Divider margin={40} size={1} />
      {fields.professionalExperience?.companies.map((company, index) => (
        <div key={company.id}>
          {index > 0 && <Divider margin={40} size={1} />}
          <CompanyForm
            fields={company}
            index={index}
            onChange={(fields) =>
              setArrayField('professionalExperience.companies', fields, index)
            }
            onDelete={() =>
              setField(
                'professionalExperience.companies',
                fields.professionalExperience?.companies.filter(
                  (item) => item.id !== company.id
                )
              )
            }
            errors={errors}
          />
        </div>
      ))}
      <LinkNewCompany
        onClick={() =>
          setField(
            'professionalExperience.companies',
            fields.professionalExperience?.companies
              ? [...fields.professionalExperience?.companies, new Company()]
              : [new Company()]
          )
        }
        label="adicionar nova empresa"
        icon="add"
      />
      {errors['professionalExperience.companies']?.error && (
        <Error>{errors['professionalExperience.companies']?.message}</Error>
      )}
    </>
  );
};

export default ProfessionalExperience;
