import api, { apiNoAuth } from 'services/api';
import Response from 'models/Response';
import User from 'models/User';
import UserSignUp from 'models/UserSignUp';

export const login = async (
  document: string,
  password: string
): Promise<Response<string>> => {
  try {
    const response = await apiNoAuth.post('/login', {
      documento: document,
      senha: password
    });

    return Response.fromData(response.data.dados.token, response.status);
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const signUp = async (fields: UserSignUp): Promise<Response<string>> => {
  try {
    const response = await apiNoAuth.post('/users', {
      nome: fields.fullName,
      documento: fields.document.replace(/[^\d]/g, ''),
      email: fields.email,
      celular: fields.cellphone.replace(/[^\d]/g, ''),
      senha: fields.password,
      aceites: {
        privacidade: fields.privacy,
        email: fields.newsletter,
        sms: fields.sms,
        whatsapp: fields.whatsapp
      }
    });

    return Response.fromData(response.data.dados, response.status);
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const readCurrentDetails = async (): Promise<Response<User>> => {
  try {
    const response = await api.get('/me');

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const forgotPassword = async (
  document: string
): Promise<Response<string>> => {
  try {
    const response = await api.post(`/users/${document}/esqueci-minha-senha`);
    return Response.fromData(response.data.dados, response.status);
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const resetPassword = async (
  document: string,
  password: string,
  code: string
): Promise<Response<string>> => {
  try {
    const response = await api.post(`/users/${document}/reset-senha`, {
      codigo: code,
      senha: password
    });
    return Response.fromData(response.data.dados, response.status);
  } catch (e: any) {
    return Response.fromError(e);
  }
};
