import * as yup from 'yup';
import { SubPage } from 'models/Page';
import { updateUxWriting } from 'services/uxSkills';
import UxWriting from './UxWriting';

const uxWritingSubpage: SubPage = {
  title: 'UX Writing',
  path: '/ux-writing',
  component: UxWriting,
  tooltip: {
    title: 'UX Writing',
    description:
      'O UX Writing é um trabalho que engloba a escolha das palavras ideais e dos formatos de conteúdo para melhorar a experiência do usuário com os pontos de contato de uma empresa, como aplicativos e chatbots.'
  },
  validationSchema: yup.object().shape({
    uxSkills: yup.object().shape({
      uxWriting: yup.object().shape({
        communication: yup.string().required(),
        redaction: yup.string().required(),
        microcopy: yup.string().required(),
        usability: yup.string().required(),
        taxonomy: yup.string().required()
      })
    })
  }),
  action: updateUxWriting
};

export default uxWritingSubpage;
