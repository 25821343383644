import * as yup from 'yup';
import { SubPage } from 'models/Page';
import { updateDigitalProduct } from 'services/uxSkills';
import DigitalProduct from './DigitalProduct';

const digitalProductSubpage: SubPage = {
  title: 'Produto digital (UI)',
  path: '/digital-product',
  component: DigitalProduct,
  tooltip: {
    title: 'Produto digital (UI)',
    description:
      'Trabalha interface, navegação e interação que tornam a experiência de uso do produto mais agradável, priorizando sempre usabilidade sobre estética.'
  },
  validationSchema: yup.object().shape({
    uxSkills: yup.object().shape({
      digitalProduct: yup.object().shape({
        design: yup.string().required(),
        documentation: yup.string().required(),
        understandTechnicalLimitations: yup.string().required(),
        visualBenchmark: yup.string().required(),
        prototyping: yup.string().required(),
        animations: yup.string().required(),
        designSystem: yup.string().required(),
        workflowOrganization: yup.string().required()
      })
    })
  }),
  action: updateDigitalProduct
};

export default digitalProductSubpage;
