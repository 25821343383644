class UserSignUp {
  fullName: string;

  document: string;

  password: string;

  email: string;

  cellphone: string;

  confirmPassword: string;

  newsletter?: boolean;

  sms?: boolean;

  whatsapp?: boolean;

  telegram?: boolean;

  privacy?: boolean;

  lgpd?: boolean;

  constructor() {
    this.fullName = '';
    this.document = '';
    this.password = '';
    this.email = '';
    this.cellphone = '';
    this.confirmPassword = '';
    this.privacy = false;
  }
}

export default UserSignUp;
