import React from 'react';
import { Divider } from '@uxparaminaspretas/components';

import { useRegisterForm } from 'provider/RegisterFormProvider';
import Institution from 'models/Institution';
import Language from 'models/Language';

import LanguagesForm from './LanguagesForm';
import InstitutionsForm from './InstitutionsForm';

import { Error, LinkEducation, StyledHeading } from './styles';

const Education: React.FC = () => {
  const { fields, errors, setField, setArrayField } = useRegisterForm();

  return (
    <>
      {fields.education?.institutions.map((institution, index) => (
        <div key={institution.id}>
          {index > 0 && <Divider margin={40} size={1} />}
          <InstitutionsForm
            fields={institution}
            index={index}
            onChange={(fields) =>
              setArrayField('education.institutions', fields, index)
            }
            onDelete={() =>
              setField(
                'education.institutions',
                fields.education?.institutions.filter(
                  (item) => item.id !== institution.id
                )
              )
            }
            errors={errors}
          />
        </div>
      ))}
      <LinkEducation
        onClick={() =>
          setField(
            'education.institutions',
            fields.education?.institutions
              ? [...fields.education?.institutions, new Institution()]
              : [new Institution()]
          )
        }
        label="adicionar curso"
        icon="add"
      />
      {errors['education.institutions']?.error && (
        <Error>{errors['education.institutions']?.message}</Error>
      )}

      <StyledHeading level={3}>Idiomas</StyledHeading>
      <Divider margin={30} size={2} />
      {fields.education?.languages.map((language, index) => (
        <div key={language.id}>
          {index > 0 && <Divider margin={40} size={1} />}
          <LanguagesForm
            fields={language}
            index={index}
            onChange={(fields) =>
              setArrayField('education.languages', fields, index)
            }
            onDelete={() => {
              setField(
                'education.languages',
                fields.education?.languages.filter(
                  (item) => item.id !== language.id
                )
              );
            }}
            errors={errors}
          />
        </div>
      ))}
      <LinkEducation
        onClick={() =>
          setField(
            'education.languages',
            fields.education?.languages
              ? [...fields.education?.languages, new Language()]
              : [new Language()]
          )
        }
        label="adicionar idioma"
        icon="add"
      />
    </>
  );
};

export default Education;
