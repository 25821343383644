import SkillLevel from './SkillLevel';
import UxResearch from './UxResearch';
import ServiceDesign from './ServiceDesign';
import InteractionUsability from './InteractionUsability';
import DigitalProduct from './DigitalProduct';
import UxWriting from './UxWriting';

class UxSkills {
  uxResearch: UxResearch;

  serviceDesign: ServiceDesign;

  interactionUsability: InteractionUsability;

  digitalProduct: DigitalProduct;

  uxWriting: UxWriting;

  constructor() {
    this.uxResearch = new UxResearch();
    this.serviceDesign = new ServiceDesign();
    this.interactionUsability = new InteractionUsability();
    this.digitalProduct = new DigitalProduct();
    this.uxWriting = new UxWriting();
  }

  static fromJson(data: any): UxSkills {
    const uxSkills = new UxSkills();
    uxSkills.uxResearch = UxResearch.fromJson(data.pesquisaUX || {});
    uxSkills.serviceDesign = ServiceDesign.fromJson(
      data.designDeServicos || {}
    );
    uxSkills.interactionUsability = InteractionUsability.fromJson(
      data.interacaoUsabilidade || {}
    );
    uxSkills.digitalProduct = DigitalProduct.fromJson(
      data.produtoDigital || {}
    );
    uxSkills.uxWriting = UxWriting.fromJson(data.uxWriting || {});

    return uxSkills;
  }

  static setSkillLevel(data: string): SkillLevel | undefined {
    const skillLevelMap: Record<string, SkillLevel> = {
      NaoConheco: SkillLevel.NOT_KNOW,
      LeioBastante: SkillLevel.READ_LOT,
      FizAlgunsTrabalhos: SkillLevel.DID_SOME_WORK,
      TrabalhoRegularmente: SkillLevel.WORK_REGULARLY,
      PlenoConhecimento: SkillLevel.FULL_KNOWLEDGE
    };

    return skillLevelMap[data];
  }
}

export default UxSkills;
