import * as yup from 'yup';
import { SubPage } from 'models/Page';
import { updateUxResearch } from 'services/uxSkills';
import UxResearch from './UxResearch';

const uxResearchSubpage: SubPage = {
  title: 'Pesquisa em UX',
  path: '/ux-research',
  component: UxResearch,
  tooltip: {
    title: 'Pesquisa em UX',
    description:
      'É a ponte entre a visão do usuário e da companhia. A prática é feita através de pesquisas qualitativas, quantitativas e do compartilhamento de descobertas, com o objetivo de melhorar a experiência de usuários.'
  },
  validationSchema: yup.object().shape({
    uxSkills: yup.object().shape({
      uxResearch: yup.object().shape({
        quantitativeResearch: yup.string().required(),
        qualitativeResearch: yup.string().required(),
        hypothesisValidation: yup.string().required(),
        fieldObservation: yup.string().required(),
        guerrilhaResearch: yup.string().required()
      })
    })
  }),
  action: updateUxResearch
};

export default uxResearchSubpage;
