import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  height: 100%;
  overflow: auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 56px;
  text-align: center;

  & img {
    width: 240px;
    height: 240px;
  }

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    padding: 24px;
  }
`;

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 24px;

  & p {
    margin: 0;
  }
`;
