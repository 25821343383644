import React from 'react';
import { Input, Button, useAlert } from '@uxparaminaspretas/components';
import { Link, useHistory } from 'react-router-dom';
import * as yup from 'yup';

import useForm from 'hooks/useForm';

import AuthNavbar from 'components/AuthNavbar';
import { forgotPassword } from 'services/authenticate';
import { ForgotPasswordForm, Title, Description, Fields } from './styles';

const ForgotPasswordSchema = yup.object().shape({
  document: yup.string().required().length(11)
});

const ForgotPassword: React.FC = () => {
  const alert = useAlert();
  const history = useHistory();

  const { setField, errors, handleSubmit, loading } = useForm(
    { document: '' },
    ForgotPasswordSchema,
    async (fields) => {
      const result = await forgotPassword(
        fields.document.replace(/[^\d]/g, '')
      );

      if (result.statusCode === 200) {
        alert.success(
          'Um link de redefinição de senha foi enviado para o seu e-mail.'
        );
        history.push('/');
      } else {
        alert.error(
          'Não encontramos uma conta válida com o documento informado.'
        );
      }
    }
  );

  return (
    <AuthNavbar>
      <ForgotPasswordForm
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Title textAlign="center" level={1}>
          Esqueceu a senha?
        </Title>
        <Description>
          Nós entendemos, esquecimentos acontecem! Basta inserir seu documento e
          enviaremos um link em seu e-mail para redefinir sua senha!
        </Description>
        <Fields>
          <Input
            name="document"
            label="CPF"
            onChange={(e) =>
              setField('document', e.target.value.replace(/[^\d]/g, ''))
            }
            placeholder="000.000.000-00"
            mask={[
              { length: 3, regexp: /^[0-9]{1,3}$/ },
              { fixed: '.' },
              { length: 3, regexp: /^[0-9]{1,3}$/ },
              { fixed: '.' },
              { length: 3, regexp: /^[0-9]{1,3}$/ },
              { fixed: '-' },
              { length: 2, regexp: /^[0-9]{1,2}$/ }
            ]}
            {...errors.document}
          />
          <Button
            loading={loading}
            type="submit"
            primary
            label="Enviar link de redefinição"
          />
          <Link to="/signin">
            <Button secondary label="Voltar" />
          </Link>
        </Fields>
      </ForgotPasswordForm>
    </AuthNavbar>
  );
};

export default ForgotPassword;
