import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Theme, globalTokens } from '@uxparaminaspretas/components';
import { ErrorBoundary } from 'react-error-boundary';

import Error500 from 'pages/Error500';
import Router from './Router';
import { GlobalStyle } from './globalStyles';

const App: React.FC = () => (
  <Theme>
    <ThemeProvider theme={globalTokens}>
      <ErrorBoundary FallbackComponent={Error500}>
        <GlobalStyle />
        <Router />
      </ErrorBoundary>
    </ThemeProvider>
  </Theme>
);

export default App;
