import styled from 'styled-components';
import {
  Subtitle,
  Link,
  Col,
  Caption,
  Heading
} from '@uxparaminaspretas/components';

export const Container = styled.div`
  position: relative;
`;

export const DateContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 24px;

  & > div {
    width: 124px;
  }

  input {
    width: 110%;
  }
`;

export const DateLabel = styled(Subtitle)`
  margin-bottom: 8px;
  margin-top: 0px;
`;

export const LinkEducation = styled(Link)`
  margin: 64px 0px 0px 0px;
`;

export const DeleteInstitutionSection = styled.section`
  position: absolute;
  right: 0;
  z-index: 100;
`;

export const ColStyled = styled(Col)`
  &.other {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const Error = styled(Caption)`
  margin-top: 8px;
  font-weight: 700;
  color: #c2463d;
  font-size: 12px;
  line-height: 16px;
`;

export const StyledHeading = styled(Heading)`
  margin-top: 64px;
`;
