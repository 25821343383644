export enum Skill {
  SELF_MANAGEMENT = 'Autogestão',
  LEADERSHIP = 'Liderança',
  PROACTIVITY = 'Proatividade',
  RESILIENCE = 'Resiliência',
  EMPATHY = 'Empatia',
  COMMUNICATION = 'Comunicação'
}

export enum Activity {
  SCHOLARSHIP = 'BolsaDeEstudos',
  COURSE = 'Curso',
  EVENT = 'Evento',
  MENTORING = 'Mentoria',
  HELP_PORTFOLIO = 'AjudaPortfolio',
  BENEFITS = 'Beneficios',
  NONE = 'Nenhuma'
}

export enum Seniority {
  OTHER = 'Não Sei',
  JUNIOR = 'Junior',
  FULL = 'Pleno',
  SENIOR = 'Senior',
  SPECIALIST = 'Especialista',
  NO_EXPERIENCE = 'Sem Experiência'
}

class UxExperience {
  haveExperience?: boolean;

  experienceTime?: number;

  migrationTime?: number;

  activities: Activity[];

  skills: Skill[];

  seniority?: Seniority;

  portfolio?: string;

  jobApplication?: boolean;

  constructor() {
    this.activities = [];
    this.skills = [];
  }

  static fromJson(data: any): UxExperience {
    const uxExperience = new UxExperience();
    uxExperience.haveExperience = data.temExperiencia;
    uxExperience.experienceTime = data.tempoExperiencia;
    uxExperience.migrationTime = data.tempoMigracao;
    uxExperience.activities = data.atividades;
    uxExperience.skills = data.habilidades;
    uxExperience.seniority = data.senioridade;
    uxExperience.portfolio = data.portfolio;
    uxExperience.jobApplication = data.emProcessoSeletivo;

    return uxExperience;
  }
}

export default UxExperience;
