import React from 'react';
import { Row, Col, Hidden, Input, Select } from '@uxparaminaspretas/components';

import states from 'constants/states';
import { useRegisterForm } from 'provider/RegisterFormProvider';
import { readAddress } from 'services/cep';

import { StyledLink } from './styles';

const Address: React.FC = () => {
  const { fields, setField, setFields, errors } = useRegisterForm();

  const searchAdressByCep = async (cep: string): Promise<void> => {
    const result = await readAddress(cep);

    if (result.data) {
      setFields({
        'address.city': result.data.city,
        'address.complement': result.data.complement,
        'address.state': result.data.state,
        'address.street': result.data.street,
        'address.number': ''
      });
    }
  };

  return (
    <Row>
      <Col xs={12} md={6}>
        <Input
          name="cep"
          label="CEP"
          placeholder="00000-000"
          mask={[
            { length: 5, regexp: /^[0-9]{1,5}$/ },
            { fixed: '-' },
            { length: 3, regexp: /^[0-9]{1,3}$/ }
          ]}
          value={fields.address?.zipCode}
          onChange={async (e) => {
            setField('address.zipCode', e.target.value);

            if (e.target.value.length === 9) {
              searchAdressByCep(e.target.value);
            }
          }}
          {...errors['address.zipCode']}
        />
        <StyledLink
          label="Não lembro meu CEP"
          href={process.env.REACT_APP_CORREIOS_URL}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6}>
        <Input
          name="logradouro"
          label="Logradouro"
          placeholder="Rua Helena"
          value={fields.address?.street}
          onChange={(e) => setField('address.street', e.target.value)}
          {...errors['address.street']}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6}>
        <Input
          name="numero"
          label="Número"
          placeholder="1234"
          value={fields.address?.number}
          onChange={(e) => setField('address.number', e.target.value)}
          {...errors['address.number']}
        />
      </Col>
      <Col xs={12} md={6}>
        <Input
          name="complemento"
          label="Complemento (opcional)"
          placeholder="Apto 51 - bloco 2"
          value={fields.address?.complement}
          onChange={(e) => setField('address.complement', e.target.value)}
          {...errors['address.complement']}
        />
      </Col>
      <Col xs={12} md={6}>
        <Input
          name="cidade"
          label="Cidade"
          placeholder="São Paulo"
          value={fields.address?.city}
          onChange={(e) => setField('address.city', e.target.value)}
          {...errors['address.city']}
        />
      </Col>
      <Col xs={12} md={6}>
        <Select
          options={states}
          labelKey="label"
          placeholder="Selecione o seu estado"
          label="Estado"
          value={
            fields.address?.state &&
            states.find((item) => item.value === fields.address?.state)
          }
          onChange={(e) => setField('address.state', e.target.value)}
          {...errors['address.state']}
        />
      </Col>
    </Row>
  );
};

export default Address;
