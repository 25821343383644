import React from 'react';
import { Row, Col } from '@uxparaminaspretas/components';

import { useRegisterForm } from 'provider/RegisterFormProvider';
import Graphic from 'components/Graphic';

const UxResearch: React.FC = () => {
  const { fields, errors, setField } = useRegisterForm();

  return (
    <Row>
      <Col xs={12}>
        <Graphic
          label="Pesquisa quantitativa"
          tooltipDescription="Método de pesquisa com o objetivo de quantificar comportamentos mais comuns dentro de um universo de pessoas."
          value={fields.uxSkills?.uxResearch.quantitativeResearch}
          onChange={(value) =>
            setField('uxSkills.uxResearch.quantitativeResearch', value)
          }
          {...errors['uxSkills.uxResearch.quantitativeResearch']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Pesquisa qualitativa"
          tooltipDescription="Método de pesquisa que tem o objetivo de identificar diferentes comportamentos, opiniões e atitudes sobre o produto, serviço ou interface."
          value={fields.uxSkills?.uxResearch.qualitativeResearch}
          onChange={(value) =>
            setField('uxSkills.uxResearch.qualitativeResearch', value)
          }
          {...errors['uxSkills.uxResearch.qualitativeResearch']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Validação de hipóteses"
          tooltipDescription="Usa diferentes ferramentas e metodologias para validar hipóteses levantadas no início do projeto."
          value={fields.uxSkills?.uxResearch.hypothesisValidation}
          onChange={(value) =>
            setField('uxSkills.uxResearch.hypothesisValidation', value)
          }
          {...errors['uxSkills.uxResearch.hypothesisValidation']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Observação em campo"
          tooltipDescription="Método que consiste em ir até onde o usuário está, mais especificamente até onde a tarefa está sendo realizada, para entender mais sobre como as pessoas se comportam naquele contexto."
          value={fields.uxSkills?.uxResearch.fieldObservation}
          onChange={(value) =>
            setField('uxSkills.uxResearch.fieldObservation', value)
          }
          {...errors['uxSkills.uxResearch.fieldObservation']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Pesquisa de guerrilha"
          tooltipDescription="Teste informal com usuários em um ambiente externo, não-controlado, sem o recrutamento prévio de pessoas."
          value={fields.uxSkills?.uxResearch.guerrilhaResearch}
          onChange={(value) =>
            setField('uxSkills.uxResearch.guerrilhaResearch', value)
          }
          {...errors['uxSkills.uxResearch.guerrilhaResearch']}
        />
      </Col>
    </Row>
  );
};

export default UxResearch;
