import React from 'react';
import { Heading, Paragraph, Button } from '@uxparaminaspretas/components';

import error404Image from 'assets/error404.svg';
import AuthNavbar from 'components/AuthNavbar';

import { Main, TextContent } from './styles';

const Error404: React.FC = () => (
  <AuthNavbar>
    <Main>
      <img src={error404Image} />
      <TextContent>
        <Heading>Página não encontrada</Heading>
        <Paragraph>
          A página que você tentou acessar está indisponível ou não existe.
        </Paragraph>
      </TextContent>
      <Button
        primary
        label="Retornar para a página anterior"
        onClick={() => window.history.go(-1)}
      />
    </Main>
  </AuthNavbar>
);

export default Error404;
