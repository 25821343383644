import React from 'react';
import {
  RadioGroup,
  Radio,
  Row,
  Col,
  Checkbox,
  CheckboxGroup
} from '@uxparaminaspretas/components';

import { useRegisterForm } from 'provider/RegisterFormProvider';
import {
  Deficiency,
  FinancialContribution,
  Genre,
  MaritalStatus,
  SelfDeclaration
} from 'models/PersonalInformation';

const PersonalInformation: React.FC = () => {
  const { fields, errors, setField, setFields } = useRegisterForm();

  return (
    <Row>
      <Col xs={12}>
        <RadioGroup
          label="Qual gênero você se identifica?"
          name="genre"
          value={fields.personalInformation?.genre}
          onChange={(value) => setField('personalInformation.genre', value)}
          {...errors['personalInformation.genre']}
        >
          <Radio
            label="Mulher Cis"
            value={Genre.CISGENDER}
            tooltip={{
              title: 'Mulher Cisgênero:',
              description:
                'Pessoa que se apresenta ao mundo e se identifica com o seu gênero biológico.'
            }}
          />
          <Radio
            label="Mulher Trans"
            value={Genre.TRANSGENDER}
            tooltip={{
              title: 'Mulher Transfeminina / Travesti:',
              description:
                'Pessoa que foi atribuída ao sexo masculino ao nascer, mas se identifica com o gênero feminino.'
            }}
          />
          <Radio
            label="Pessoa não binária"
            value={Genre.NON_BINARY}
            tooltip={{
              title: 'Pessoa não binária:',
              description:
                'Pessoa cuja identidade ou expressão de gênero não se limita às categorias "masculino" ou "feminino".'
            }}
          />
        </RadioGroup>
      </Col>
      <Col xs={12}>
        <RadioGroup
          label="Como você se autodeclara?"
          name="selfDeclaration"
          value={fields.personalInformation?.selfDeclaration}
          onChange={(value) =>
            setField('personalInformation.selfDeclaration', value)
          }
          {...errors['personalInformation.selfDeclaration']}
        >
          <Radio label="Preta" value={SelfDeclaration.BLACK} />
          <Radio label="Parda" value={SelfDeclaration.BROWN} />
          <Radio label="Branca" value={SelfDeclaration.WHITE} />
          <Radio label="Indígena" value={SelfDeclaration.INDIGENOUS} />
          <Radio label="Amarela" value={SelfDeclaration.YELLOW} />
        </RadioGroup>
      </Col>
      <Col xs={12}>
        <RadioGroup
          label="Qual o seu estado civil?"
          name="maritalStatus"
          value={fields.personalInformation?.maritalStatus}
          onChange={(value) =>
            setField('personalInformation.maritalStatus', value)
          }
          {...errors['personalInformation.maritalStatus']}
        >
          <Radio label="Solteira" value={MaritalStatus.SINGLE} />
          <Radio label="União estável" value={MaritalStatus.STABLE_UNION} />
          <Radio label="Casada" value={MaritalStatus.MARRIED} />
          <Radio label="Separada/divorciada" value={MaritalStatus.DIVORCED} />
          <Radio label="Viúva" value={MaritalStatus.WIDOW} />
        </RadioGroup>
      </Col>
      <Col xs={12}>
        <RadioGroup
          label="Quantos filhos você tem? (Biológicos ou não)"
          name="sons"
          value={fields.personalInformation?.sons}
          onChange={(value) => setField('personalInformation.sons', value)}
          {...errors['personalInformation.sons']}
        >
          <Radio label="1" value={1} />
          <Radio label="2" value={2} />
          <Radio label="3" value={3} />
          <Radio label="4+" value={4} />
          <Radio label="Não tenho filhos" value={0} />
        </RadioGroup>
      </Col>
      <Col xs={12}>
        <RadioGroup
          label="Como é a sua contribuição financeira na sua casa?"
          name="financialContribution"
          value={fields.personalInformation?.financialContribution}
          onChange={(value) =>
            setField('personalInformation.financialContribution', value)
          }
          {...errors['personalInformation.financialContribution']}
        >
          <Radio
            label="Sou a única/principal responsável pela renda."
            value={FinancialContribution.MAIN}
          />
          <Radio
            label="Ajudo nas despesas mas não sou a principal responsável pela renda."
            value={FinancialContribution.HELP}
          />
          <Radio
            label="Não ajudo nas despesas."
            value={FinancialContribution.NONE}
          />
        </RadioGroup>
      </Col>
      <Col xs={12}>
        <RadioGroup
          label="Você é uma pessoa com deficiência??"
          name="hasDeficiency"
          value={`${fields.personalInformation?.hasDeficiency}`}
          onChange={(value) => {
            if (value === 'true') {
              setField('personalInformation.hasDeficiency', true);
            } else {
              setFields({
                'personalInformation.hasDeficiency': false,
                'personalInformation.deficiency': []
              });
            }
          }}
          {...errors['personalInformation.hasDeficiency']}
        >
          <Radio label="Sim" value="true" />
          <Radio label="Não" value="false" />
          <Radio label="Prefiro não responder" />
        </RadioGroup>
      </Col>
      {fields.personalInformation?.hasDeficiency && (
        <Col xs={12}>
          <CheckboxGroup
            label="Informe qual ou quais são:"
            name="deficiency"
            value={fields.personalInformation?.deficiency}
            onChange={(value) =>
              setField('personalInformation.deficiency', value)
            }
            {...errors['personalInformation.deficiency']}
          >
            <Checkbox label="Visual" value={Deficiency.VISUAL} />
            <Checkbox label="Auditiva" value={Deficiency.HEARING} />
            <Checkbox label="Física" value={Deficiency.PHYSICAL} />
            <Checkbox label="Intelectual" value={Deficiency.INTELLECTUAL} />
            <Checkbox
              label="Prefiro não responder"
              value={Deficiency.NOT_ANSWER}
            />
          </CheckboxGroup>
        </Col>
      )}
    </Row>
  );
};

export default PersonalInformation;
