import Company from './Company';

class ProfessionalExperience {
  summary?: string;

  companies: Company[];

  constructor() {
    this.companies = [];
  }

  static fromJson(data: any): ProfessionalExperience {
    const professionalExperience = new ProfessionalExperience();
    professionalExperience.summary = data.resumo;
    professionalExperience.companies =
      data.empresas?.map((item: any) => Company.fromJson(item)) || [];

    if (professionalExperience.companies.length === 0) {
      professionalExperience.companies = [new Company()];
    }

    return professionalExperience;
  }
}

export default ProfessionalExperience;
