import { useState } from 'react';
import { ObjectSchema, ValidationError } from 'yup';

export interface IErrors {
  error: boolean;
  message: string;
}

export interface IUseFormReturn<T> {
  fields: Partial<T>;
  handleSubmit: () => void;
  onReset: () => void;
  loading: boolean;
  isSubmitted: boolean;
  errors: Partial<{ [K in keyof T]: IErrors }>;
  setField: (name: keyof T, value: string | number | boolean) => void;
  setAllFields: (data: T) => void;
}

function useForm<T>(
  fieldsDefault: Partial<T>,
  validationSchema?: ObjectSchema<any>,
  onSubmit?: (fields: T) => void
): IUseFormReturn<T> {
  const [loading, setLoading] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [fields, setFields] = useState<Partial<T>>(fieldsDefault);
  const [errors, setErrors] = useState<Partial<{ [K in keyof T]: IErrors }>>(
    {}
  );

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);
    let isValid = false;

    if (validationSchema) {
      try {
        await validationSchema.validate(fields, { abortEarly: false });
        setErrors({});
        isValid = true;
      } catch (e) {
        const error = e as unknown as ValidationError;
        setErrors(
          error.inner.reduce(
            (acc, error) => ({
              ...acc,
              [error.path!]: { error: true, message: error.message }
            }),
            {}
          )
        );
        isValid = false;
      }
    }

    if (isValid && onSubmit) {
      await onSubmit(fields as T);
    }

    setSubmitted(true);
    setLoading(false);
  };

  const handleReset = (): void => {
    setSubmitted(false);
    setLoading(false);
    setFields(fieldsDefault);
  };

  const setField = (name: keyof T, value: any): void => {
    setFields({ ...fields, [name]: value } as T);
  };

  const setAllFields = (data: T): void => {
    setFields(data);
  };

  return {
    errors,
    handleSubmit,
    onReset: handleReset,
    setField,
    setAllFields,
    fields,
    loading,
    isSubmitted
  };
}

export default useForm;
