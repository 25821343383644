import React from 'react';
import {
  Input,
  Row,
  Col,
  Hidden,
  Button,
  useAlert,
  Checker
} from '@uxparaminaspretas/components';
import * as yup from 'yup';
import { Link } from 'react-router-dom';

import useForm from 'hooks/useForm';
import { changePassword } from 'services/user';
import { useAuth } from 'provider/AuthProvider';

import { ButtonContent, PasswordValidateBox } from './styles';

const ChangePasswordSchema = yup.object().shape({
  currentPassword: yup.string().required(),
  newPassword: yup.string().required(),
  repeatNewPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('newPassword'), null], 'As senhas devem ter o mesmo valor.')
});

const passwordRules = (password: string): boolean[] => [
  password.length >= 8,
  /[A-Z]/.test(password),
  /[a-z]/.test(password),
  /\d/.test(password),
  /[^0-9a-zA-Z]/.test(password)
];

const ChangePassword: React.FC = () => {
  const { user } = useAuth();
  const alert = useAlert();
  const { fields, setField, errors, handleSubmit, loading } = useForm(
    { currentPassword: '', newPassword: '', repeatNewPassword: '' },
    ChangePasswordSchema,
    async (fields) => {
      const isPasswordValid = passwordRules(fields.newPassword).every(
        (rule) => rule
      );

      if (!isPasswordValid) {
        return;
      }

      const result = await changePassword(
        fields.currentPassword,
        fields.newPassword,
        user
      );

      if (result.data) {
        alert.success('Senha atualizada com sucesso.');
      } else {
        alert.error(
          'Não foi possível atualizar a senha. Por favor, verifique os valores e tente novamente.'
        );
      }
    }
  );

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit();
      }}
    >
      <Row>
        <Col xs={12} md={6}>
          <Input
            name="currentPassword"
            label="Digite sua senha atual"
            type="password"
            onChange={(e) => setField('currentPassword', e.target.value)}
            {...errors.currentPassword}
          />
        </Col>
        <Hidden xs sm>
          <Col xs={12} md={6} />
        </Hidden>
        <Col xs={12} md={6}>
          <Input
            name="newPassword"
            type="password"
            label="Criar nova senha"
            onChange={(e) => setField('newPassword', e.target.value)}
            {...errors.newPassword}
          />
        </Col>
        <Hidden xs sm>
          <Col xs={12} md={6} />
        </Hidden>
        <Col xs={12} md={6}>
          <PasswordValidateBox>
            <Input
              name="repeatNewPassword"
              type="password"
              label="Confirmar nova senha"
              onChange={(e) => setField('repeatNewPassword', e.target.value)}
              {...errors.repeatNewPassword}
            />
            <Checker
              messages={[
                'Deve ter no mínimo 8 caracteres',
                'Ao menos uma letra maiúscula',
                'Ao menos uma letra minúscula',
                'Ao menos um número',
                'Ao menos um caractere especial'
              ]}
              rules={
                fields.newPassword ? passwordRules(fields.newPassword) : []
              }
            />
          </PasswordValidateBox>
        </Col>
        <Hidden xs sm>
          <Col xs={12} md={6} />
        </Hidden>
        <Col xs={12} md={6}>
          <ButtonContent>
            <Button
              type="submit"
              loading={loading}
              primary
              label="Alterar senha"
            />
            <Link to="/my-profile">
              <Button type="button" label="Voltar" />
            </Link>
          </ButtonContent>
        </Col>
      </Row>
    </form>
  );
};

export default ChangePassword;
