import Page from 'models/Page';

import initialDataSubpage from './InitialData';
import addressSubpage from './Address';
import socialMediaSubpage from './SocialMedia';

const contactDetailsPage: Page = {
  type: 'subpage',
  path: '/contact-details',
  description: 'Configuração do perfil',
  title: 'Dados de contato',
  subtitle:
    'Olá! Queremos conhecer você para te ajudar no mundo do UX. Vamos iniciar pelos seus dados de contato.',
  icon: 'phone',
  subpages: [initialDataSubpage, addressSubpage, socialMediaSubpage]
};

export default contactDetailsPage;
