import defaultAvatar from 'assets/defaultAvatar.png';

class Photo {
  url?: string;

  mime?: string;

  name?: string;

  content?: string;

  static fromJson(data: any): Photo {
    const photo = new Photo();
    photo.url = data.url;

    return photo;
  }

  getPhoto(): string {
    return this.content || this.url || defaultAvatar;
  }
}

export default Photo;
