export enum Genre {
  CISGENDER = 'Cisgenero',
  TRANSGENDER = 'Transgenero',
  TRANSSEXUAL = 'Transexual',
  NON_BINARY = 'NaoBinario'
}

export enum SelfDeclaration {
  BLACK = 'Preta',
  BROWN = 'Parda',
  WHITE = 'Branca',
  INDIGENOUS = 'Indigena',
  YELLOW = 'Amarela'
}

export enum MaritalStatus {
  SINGLE = 'Solteira',
  STABLE_UNION = 'UniaoEstavel',
  MARRIED = 'Casada',
  DIVORCED = 'Divorciada',
  WIDOW = 'Viuva'
}

export enum FinancialContribution {
  MAIN = 'Principal',
  HELP = 'Auxiliar',
  NONE = 'Nenhuma'
}

export enum Deficiency {
  VISUAL = 'Visual',
  HEARING = 'Auditiva',
  PHYSICAL = 'Física',
  INTELLECTUAL = 'Intelectual',
  NOT_ANSWER = 'Prefiro não responder'
}

class PersonalInformation {
  genre?: Genre;

  selfDeclaration?: SelfDeclaration;

  maritalStatus?: MaritalStatus;

  financialContribution?: FinancialContribution;

  sons?: number;

  hasDeficiency?: boolean;

  deficiency: Deficiency[];

  constructor() {
    this.deficiency = [];
  }

  static fromJson(data: any): PersonalInformation {
    const personalInformation = new PersonalInformation();
    personalInformation.genre = data.identificacao?.genero;
    personalInformation.selfDeclaration = data.identificacao?.autoDeclaracao;
    personalInformation.maritalStatus = data.identificacao?.estadoCivil;
    personalInformation.financialContribution =
      data.renda?.contribuicaoFinanceira;
    personalInformation.sons = data.renda?.filhos;
    personalInformation.deficiency = data.deficiencia;

    if (personalInformation.deficiency) {
      personalInformation.hasDeficiency =
        personalInformation.deficiency.length > 0;
    }

    return personalInformation;
  }
}

export default PersonalInformation;
