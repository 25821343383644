import styled from 'styled-components';

export const Main = styled.main`
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow: auto;

  @media only screen and (max-width: 768px) {
    padding-top: 72px;
  }
`;

export const Root = styled.section`
  flex: 1;
`;

export const Container = styled.div`
  margin: 40px 80px;

  @media only screen and (max-width: 768px) {
    margin: 40px 16px;
  }
`;
