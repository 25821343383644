import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ENVIRONMENT}`
});
const axiosNoAuthInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}/${process.env.REACT_APP_ENVIRONMENT}`
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('@uxmp:token');

    return {
      ...config,
      headers: { ...config.headers, Authorization: `Bearer ${token}` }
    };
  },
  (error) => {
    return Promise.reject(error);
  }
);

export const apiNoAuth = axiosNoAuthInstance;
export default axiosInstance;
