import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import { AuthProvider } from 'provider/AuthProvider';

import SignIn from 'pages/SignIn';
import SignUp from 'pages/SignUp';
import ForgotPassword from 'pages/ForgotPassword';
import Home from 'pages/Home';
import Error404 from 'pages/Error404';
import ResetForgotPassword from 'pages/ResetForgotPassword';

const Router: React.FC = () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/signin">
        <SignIn />
      </Route>
      <Route exact path="/signup">
        <SignUp />
      </Route>
      <Route exact path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route exact path="/reset-forgot-password">
        <ResetForgotPassword />
      </Route>
      <Route exact path="/404">
        <Error404 />
      </Route>
      <Route path="*" exact>
        <AuthProvider>
          <Home />
        </AuthProvider>
      </Route>
    </Switch>
  </BrowserRouter>
);

export default Router;
