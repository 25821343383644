import * as yup from 'yup';
import { SubPage } from 'models/Page';
import { updateSocialMedia } from 'services/user';
import SocialMedia from './SocialMedia';

const FormSchema = yup.object().shape({
  socialMedia: yup.object().shape(
    {
      facebook: yup.string().url().required(),
      instagram: yup.string().required(),
      linkedin: yup.string().url().required()
    },
    [
      ['facebook', 'instagram'],
      ['facebook', 'linkedin'],
      ['facebook', 'telegram'],
      ['instagram', 'linkedin'],
      ['instagram', 'telegram'],
      ['linkedin', 'telegram']
    ]
  )
});

const socialMediaSubpage: SubPage = {
  title: 'Mídias sociais',
  path: '/social-media',
  component: SocialMedia,
  validationSchema: FormSchema,
  // completed: (user) => !!user.socialMedia?.isCompleted,
  action: updateSocialMedia
};

export default socialMediaSubpage;
