import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Subtitle } from '@uxparaminaspretas/components';

export const Logo = styled.img`
  width: 60px;
  max-width: 100%;
  margin-bottom: 64px;
`;

export const Title = styled(Subtitle)`
  margin-bottom: 48px;
  font-size: 20px;
  font-weight: bold;
`;

export const StyledSidebar = styled.nav<{ $menuOpen: boolean }>`
  width: 300px;
  padding: 48px 32px;
  background: ${(props) => props.theme.colors.brand};
  transform: none;
  align-self: stretch;
  font-size: 16px;

  & * {
    color: #fff;
  }

  & a {
    display: block;
    width: 100%;
    margin-bottom: 10px;
  }

  @media only screen and (max-width: 768px) {
    transform: ${(props) => (props.$menuOpen ? 'none' : 'translateX(-100vw)')};
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    top: 0;
    z-index: 120;
    overflow-y: scroll;
    transition: transform 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
  transition: 0.2s all;

  &:hover {
    opacity: 0.4;
  }
`;

export const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  cursor: pointer;
  transition: 0.2s all;
  position: absolute;
  top: 48px;
  right: 32px;
  padding: 0;

  &:hover {
    opacity: 0.8;
  }
`;

export const MarginTop = styled.div`
  margin-top: 64px;
`;
