import api from 'services/api';
import Response from 'models/Response';
import User from 'models/User';
import { onlyNumbers } from 'core/mask';
import { SocialMediaGroups } from 'models/SocialMedia';

export const changePassword = async (
  password: string,
  newPassword: string,
  user: User
): Promise<Response<string>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/atualizar-senha`,
      {
        senha: password,
        novaSenha: newPassword
      }
    );

    return Response.fromData('Sucesso', response.status);
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updateInitialData = async (
  user: User
): Promise<Response<User>> => {
  try {
    if (user.photo.content) {
      await updatePhoto(user);
    }
    const birthDate = new Date(user.birthDate!);

    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/dados-iniciais`,
      {
        nome: user.name,
        dataNascimento: new Date(
          birthDate.getTime() + Math.abs(birthDate.getTimezoneOffset() * 60000)
        ),
        email: user.email,
        celular: user.cellphone?.replace(/[^\d]/g, '')
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updatePhoto = async (user: User): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/foto`,
      {
        imagem: user.photo.content?.split(',')[1],
        mime: user.photo.mime,
        nome: user.photo.name
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updateAddress = async (user: User): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/endereco`,
      {
        cep: user.address.zipCode?.replace(/[^\d]/g, ''),
        logradouro: user.address.street,
        numero: user.address.number,
        complemento: user.address.complement,
        cidade: user.address.city,
        estado: user.address.state
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updateSocialMedia = async (
  user: User
): Promise<Response<User>> => {
  try {
    const facebook = user.socialMedia.groups?.includes(
      SocialMediaGroups.FACEBOOK
    );

    const slack = user.socialMedia.groups?.includes(SocialMediaGroups.SLACK);

    const grupos = {
      facebook,
      slack
    };

    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/social`,
      {
        facebook: user.socialMedia.facebook,
        instagram: user.socialMedia.instagram,
        linkedin: user.socialMedia.linkedin,
        telegram: user.socialMedia.telegram,
        slack: user.socialMedia.slack,
        grupos
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updateUxExperience = async (
  user: User
): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/experiencia-ux`,
      {
        temExperiencia: user.uxExperience.haveExperience,
        tempoExperiencia: user.uxExperience.experienceTime,
        tempoMigracao: user.uxExperience.migrationTime,
        atividades: user.uxExperience.activities,
        habilidades: user.uxExperience.skills,
        senioridade: user.uxExperience.seniority,
        portfolio: user.uxExperience.portfolio,
        emProcessoSeletivo: user.uxExperience.jobApplication
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updatePersonalInformation = async (
  user: User
): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/dados-pessoais`,
      {
        genero: user.personalInformation.genre,
        autoDeclaracao: user.personalInformation.selfDeclaration,
        estadoCivil: user.personalInformation.maritalStatus,
        filhos: user.personalInformation.sons,
        contribuicaoFinanceira: user.personalInformation.financialContribution,
        deficiencia: user.personalInformation.deficiency
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updateProfessionalExperience = async (
  user: User
): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/experiencia-profissional`,
      {
        resumo: user.professionalExperience.summary,
        empresas: user.professionalExperience.companies.map((item) => ({
          nome: item.name,
          atual: item.current,
          cargo: item.position,
          tipo: item.type,
          dataInicio: `01-${item.monthStartDate
            ?.toString()
            .padStart(2, '0')}-${item.yearStartDate
            ?.toString()
            .padStart(4, '0')}`,
          dataTermino: item.current
            ? undefined
            : `01-${item.monthEndDate
                ?.toString()
                .padStart(2, '0')}-${item.yearEndDate
                ?.toString()
                .padStart(4, '0')}`,
          atividades: item.activities,
          ajudaUXMP: item.uxmpHelp
          // gruposUXMP: item.uxmpGroups,
          // indicacaoUXMP: item.uxmpIndication
        }))
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updatePrivacy = async (
  user: Partial<User>
): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/privacidade`,
      {
        privacidade: user.privacy
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updateEducation = async (user: User): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/educacao`,
      {
        instituicoes: user.education.institutions.map((item) => ({
          nome: item.name,
          diploma: item.degree,
          curso: item.course,
          dataInicio: `01-${item.monthStartDate
            ?.toString()
            .padStart(2, '0')}-${item.yearStartDate
            ?.toString()
            .padStart(4, '0')}`,
          dataFinal:
            item.monthEndDate && item.yearEndDate
              ? `01-${item.monthEndDate
                  ?.toString()
                  .padStart(2, '0')}-${item.yearEndDate
                  ?.toString()
                  .padStart(4, '0')}`
              : undefined
        })),
        idiomas: user.education.languages.map((item) => ({
          nome: item.name,
          nivel: item.level
        }))
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};
