import React, { useEffect, useState } from 'react';
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  Redirect
} from 'react-router-dom';
import {
  Heading,
  Icon,
  globalTokens,
  Divider,
  Stepper
} from '@uxparaminaspretas/components';

import { useFields } from 'provider/RegisterFormProvider';
import { useAuth } from 'provider/AuthProvider';
import Page from 'models/Page';
import { isSubpageCompleted } from 'utils/isCompleted';

import SubpageRouter from '../SubpageRouter';
import FlowButtons from '../FlowButtons';
import {
  StyledSubtitle,
  IconTitleContent,
  StepperContainer,
  StyledParagraph
} from './styles';

interface IPageRouter {
  path: string;
  exact: boolean;
  page: Page;
  previousPage?: Page;
  nextPage?: Page;
}

const PageRouter: React.FC<IPageRouter> = ({
  page,
  previousPage,
  nextPage
}) => {
  const fields = useFields();
  const history = useHistory();
  const location = useLocation();
  const { user } = useAuth();
  const [completeds, setCompleteds] = useState<boolean[]>([]);
  const [current, setCurrent] = useState<number>(0);

  const isPage = page.type === 'page';

  useEffect(() => {
    if (location.pathname === page.path && !isPage) {
      history.push(`${page.path}${page.subpages[0].path}`);
    }
  }, [location, isPage]);

  useEffect(() => {
    const readCompleteds = async (): Promise<void> => {
      if (!isPage) {
        const newCompleteds = await Promise.all(
          page.subpages.map((subpage) => isSubpageCompleted(subpage, fields))
        );
        setCompleteds(newCompleteds);

        const newCurrent = newCompleteds.findIndex((item) => item === false);
        setCurrent(newCurrent);

        if (current > -1) {
          history.push(`${page.path}${page.subpages[current].path}`);
        }
      }
    };

    readCompleteds();
  }, [user, current]);

  return (
    <Route exact={isPage} path={page.path}>
      <>
        <StyledSubtitle>{page.description}</StyledSubtitle>
        <IconTitleContent>
          <Icon icon={page.icon} color={globalTokens.colors.brand} size={40} />
          <Heading>{page.title}</Heading>
        </IconTitleContent>
        <StyledParagraph>{page.subtitle}</StyledParagraph>
        {isPage && (
          <>
            <Divider margin={32} />
            <page.component />
            <FlowButtons
              page={page}
              hiddenFlowButtons={page.hiddenFlowButtons}
              previousPagePath={
                previousPage?.type === 'subpage'
                  ? `${previousPage.path}${
                      previousPage.subpages[previousPage.subpages.length - 1]
                        .path
                    }`
                  : previousPage?.path
              }
              nextPagePath={nextPage?.path}
            />
          </>
        )}
        {!isPage && (
          <>
            <StepperContainer>
              <Stepper
                variant="horizontal"
                onStepClick={(step) =>
                  history.push(`${page.path}${step.props.$subpage.path}`)
                }
                steps={page.subpages.map((subpage, index) => {
                  const isCurrent = index === current;
                  const completed = completeds[index];
                  const nonCompleted = !completed || isCurrent;

                  const data = {
                    title: subpage.title,
                    disabled: !completed && !isCurrent,
                    selected:
                      location.pathname === `${page.path}${subpage.path}`,
                    props: { $subpage: subpage }
                  };

                  if (completed) {
                    return { ...data, status: 'success' };
                  }
                  if (isCurrent) {
                    return data;
                  }
                  if (nonCompleted) {
                    return { ...data, status: 'unchecked' };
                  }
                  return data;
                })}
              />
            </StepperContainer>
            <Switch>
              {page.subpages.map((subpage, index) => (
                <SubpageRouter
                  key={subpage.path}
                  path={`${page.path}${subpage.path}`}
                  exact
                  page={page}
                  subpage={subpage}
                  previousSubpage={page.subpages[index - 1]}
                  nextSubpage={page.subpages[index + 1]}
                  previousPage={previousPage}
                  nextPage={nextPage}
                />
              ))}
              <Redirect to="/404" />
            </Switch>
          </>
        )}
      </>
    </Route>
  );
};

export default PageRouter;
