import React from 'react';
import {
  Graphic as GraphicComponent,
  GraphicRange,
  GraphicProps
} from '@uxparaminaspretas/components';

import { Content, MobileContent } from './styles';

const Graphic: React.FC<GraphicProps> = (props) => (
  <>
    <Content>
      <GraphicComponent {...props} />
    </Content>
    <MobileContent>
      <GraphicRange {...props} />
    </MobileContent>
  </>
);

export default Graphic;
