import React from 'react';
import {
  Row,
  Col,
  RadioGroup,
  Radio,
  CheckboxGroup,
  Checkbox,
  Input,
  Select,
  Hidden
} from '@uxparaminaspretas/components';
import { Skill, Activity, Seniority } from 'models/UxExperience';
import { useRegisterForm } from 'provider/RegisterFormProvider';

const seniorityMap = [
  { name: 'Sem Experiência', value: Seniority.NO_EXPERIENCE },
  { name: 'Júnior', value: Seniority.JUNIOR },
  { name: 'Pleno', value: Seniority.FULL },
  { name: 'Sênior', value: Seniority.SENIOR },
  { name: 'Especialista', value: Seniority.SPECIALIST },
  { name: 'Não sei', value: Seniority.OTHER }
];

const UxExperience: React.FC = () => {
  const { fields, errors, setField, setFields } = useRegisterForm();

  return (
    <Row>
      <Col xs={12}>
        <RadioGroup
          label="Você já trabalhou ou trabalha com experiência do usuário (UX)?"
          name="haveExperience"
          value={`${fields.uxExperience?.haveExperience}`}
          onChange={(value) => {
            if (value === 'true') {
              setField('uxExperience.haveExperience', true);
            } else {
              setFields({
                'uxExperience.haveExperience': false,
                'uxExperience.experienceTime': undefined
              });
            }
          }}
          {...errors['uxExperience.haveExperience']}
        >
          <Radio label="Sim" value="true" />
          <Radio label="Não" value="false" />
        </RadioGroup>
      </Col>
      {fields.uxExperience?.haveExperience !== undefined && (
        <>
          {fields.uxExperience.haveExperience && (
            <Col xs={12}>
              <RadioGroup
                label="Possui experiência de quanto tempo?"
                name="experienceTime"
                value={fields.uxExperience.experienceTime}
                onChange={(value) =>
                  setField('uxExperience.experienceTime', value)
                }
                {...errors['uxExperience.experienceTime']}
              >
                <Radio label="0 - 6 meses" value={0} />
                <Radio label="6 meses - 1 ano" value={6} />
                <Radio label="1 - 2 anos" value={12} />
                <Radio label="3 - 5 anos" value={36} />
                <Radio label="5 anos +" value={60} />
              </RadioGroup>
            </Col>
          )}
          <Col xs={12}>
            <RadioGroup
              label={
                fields.uxExperience.haveExperience
                  ? 'Por quanto tempo você buscou pela migração?'
                  : 'Há quanto tempo você busca pela migração?'
              }
              name="migrationTime"
              value={fields.uxExperience.migrationTime}
              onChange={(value) =>
                setField('uxExperience.migrationTime', value)
              }
              {...errors['uxExperience.migrationTime']}
            >
              <Radio label="0 - 6 meses" value={0} />
              <Radio label="6 meses - 1 ano" value={6} />
              <Radio label="1 - 2 anos" value={12} />
              <Radio label="Mais de 2 anos" value={24} />
            </RadioGroup>
          </Col>
          {!fields.uxExperience?.haveExperience && (
            <Col xs={12}>
              <RadioGroup
                label="Você está se candidatando a vagas na área de experiência do usuário (UX)?"
                name="jobApplication"
                value={`${fields.uxExperience?.jobApplication}`}
                onChange={(value) => {
                  if (value === 'true') {
                    setField('uxExperience.jobApplication', true);
                  } else {
                    setFields({
                      'uxExperience.jobApplication': false
                    });
                  }
                }}
                {...errors['uxExperience.jobApplication']}
              >
                <Radio label="Sim" value="true" />
                <Radio label="Não" value="false" />
              </RadioGroup>
            </Col>
          )}
        </>
      )}
      <Col xs={12}>
        <CheckboxGroup
          label="Você realizou alguma das atividades abaixo oferecidas pela UXMP?"
          name="activities"
          value={fields.uxExperience?.activities}
          onChange={(value) => setField('uxExperience.activities', value)}
          {...errors['uxExperience.activities']}
        >
          <Checkbox
            label="Recebeu uma bolsa de estudos oferecida pela UXMP em alguma escola de UX"
            value={Activity.SCHOLARSHIP}
          />
          <Checkbox
            label="Participou de algum curso oferecido diretamente pela UXMP"
            value={Activity.COURSE}
          />
          <Checkbox
            label="Participou de algum evento/workshop/meetup organizado pela UXMP"
            value={Activity.EVENT}
          />
          <Checkbox
            label="Participou de alguma mentoria oferecida pela UXMP"
            value={Activity.MENTORING}
          />
          <Checkbox
            label="Recebeu ajuda da UXMP para elaborar ou ajustar seu portfólio"
            value={Activity.HELP_PORTFOLIO}
          />
          <Checkbox
            label="Recebeu algum benefício como licença ou acessos a plataformas"
            value={Activity.BENEFITS}
          />
        </CheckboxGroup>
      </Col>
      <Col xs={12}>
        <CheckboxGroup
          label="Você se identifica em quais dessas habilidades?"
          name="skills"
          value={fields.uxExperience?.skills}
          onChange={(value) => setField('uxExperience.skills', value)}
          {...errors['uxExperience.skills']}
        >
          <Checkbox
            label="Autogestão ou autonomia"
            value={Skill.SELF_MANAGEMENT}
          />
          <Checkbox label="Liderança" value={Skill.LEADERSHIP} />
          <Checkbox label="Proatividade" value={Skill.PROACTIVITY} />
          <Checkbox
            label="Flexibilidade e resiliência"
            value={Skill.RESILIENCE}
          />
          <Checkbox label="Empatia" value={Skill.EMPATHY} />
          <Checkbox label="Comunicação" value={Skill.COMMUNICATION} />
        </CheckboxGroup>
      </Col>
      <Col xs={12} md={7}>
        <Select
          name="seniority"
          options={seniorityMap}
          placeholder="Selecione o nível de senioridade"
          label="Você se considera em qual nível de senioridade na área de UX?"
          value={seniorityMap.find(
            (item) => item.value === fields.uxExperience?.seniority
          )}
          labelKey="name"
          onChange={({ option }) =>
            setField('uxExperience.seniority', option.value)
          }
          {...errors['uxExperience.seniority']}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={5} />
      </Hidden>
      <Col xs={12} md={7}>
        <Input
          name="portfolio"
          label="Você tem portfólio? Compartilha com a gente:"
          placeholder="Insira o link do portfólio"
          value={fields.uxExperience?.portfolio}
          onChange={(e) => setField('uxExperience.portfolio', e.target.value)}
          {...errors['uxExperience.portfolio']}
        />
      </Col>
    </Row>
  );
};

export default UxExperience;
