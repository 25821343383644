import SkillLevel from './SkillLevel';
import UxSkills from './UxSkills';

class UxResearch {
  quantitativeResearch?: SkillLevel;

  qualitativeResearch?: SkillLevel;

  hypothesisValidation?: SkillLevel;

  fieldObservation?: SkillLevel;

  guerrilhaResearch?: SkillLevel;

  static fromJson(data: any): UxResearch {
    const uxResearch = new UxResearch();
    uxResearch.quantitativeResearch = UxSkills.setSkillLevel(
      data.pesquisaQuantitativa
    );
    uxResearch.qualitativeResearch = UxSkills.setSkillLevel(
      data.pesquisaQualitativa
    );
    uxResearch.hypothesisValidation = UxSkills.setSkillLevel(
      data.validacaoDeHipoteses
    );
    uxResearch.fieldObservation = UxSkills.setSkillLevel(
      data.observacaoEmCampo
    );
    uxResearch.guerrilhaResearch = UxSkills.setSkillLevel(
      data.pesquisaDeGuerrilha
    );

    return uxResearch;
  }
}

export default UxResearch;
