import * as yup from 'yup';
import Page from 'models/Page';
import { updateUxExperience } from 'services/user';
import UxExperience from './UxExperience';

const uxExperiencePage: Page = {
  type: 'page',
  path: '/ux-experience',
  description: 'Configuração do perfil',
  title: 'Experiência em UX',
  subtitle:
    'Nos conte mais sobre suas experiências profissionais. Esses dados são importantes para no futuro ajudarmos você a encontrar vagas de acordo com o que você deseja.',
  icon: 'local_library',
  component: UxExperience,
  validationSchema: yup.object().shape({
    uxExperience: yup.object().shape({
      haveExperience: yup.boolean().required(),
      experienceTime: yup.number().when('haveExperience', {
        is: (haveExperience: boolean) => haveExperience,
        then: yup.number().required()
      }),
      migrationTime: yup.number().required(),
      activities: yup.array().of(yup.string()).required(),
      skills: yup.array().of(yup.string()).min(1),
      seniority: yup.string().required(),
      portfolio: yup.string()
    })
  }),
  action: updateUxExperience
};

export default uxExperiencePage;
