import api from 'services/api';
import Response from 'models/Response';
import User from 'models/User';
import { onlyNumbers } from 'core/mask';

export const updateUxResearch = async (user: User): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/conhecimento-pesquisa-ux`,
      {
        pesquisaQuantitativa: user.uxSkills.uxResearch.quantitativeResearch,
        pesquisaQualitativa: user.uxSkills.uxResearch.qualitativeResearch,
        validacaoDeHipoteses: user.uxSkills.uxResearch.hypothesisValidation,
        observacaoEmCampo: user.uxSkills.uxResearch.fieldObservation,
        pesquisaDeGuerrilha: user.uxSkills.uxResearch.guerrilhaResearch
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updateServiceDesign = async (
  user: User
): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/conhecimento-design-de-servicos`,
      {
        mapeamentoDaJornadaDoCliente:
          user.uxSkills.serviceDesign.customerJourneyMapping,
        storytelling: user.uxSkills.serviceDesign.storytelling,
        inovacao: user.uxSkills.serviceDesign.innovation,
        organizacaoDaInformacao:
          user.uxSkills.serviceDesign.organizationOfInformation,
        organizacaoDeIdeias: user.uxSkills.serviceDesign.organizationOfIdeas,
        moderacaoDeGrupos: user.uxSkills.serviceDesign.groupModeration,
        blueprintDoServico: user.uxSkills.serviceDesign.serviceBlueprint
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updateInteractionUsability = async (
  user: User
): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(
        user.document!
      )}/conhecimento-interacao-e-usabilidade`,
      {
        principiosDeUsabilidade:
          user.uxSkills.interactionUsability.usabilityPrinciples,
        abordagensCentradasNoSerHumano:
          user.uxSkills.interactionUsability.humanCenteredApproaches,
        analiseDeBenchmarking:
          user.uxSkills.interactionUsability.benchmarkingAnalysis,
        prototipacao: user.uxSkills.interactionUsability.prototyping,
        construcaoDeWireframes:
          user.uxSkills.interactionUsability.wireframeConstruction,
        identificacaoDePadroes:
          user.uxSkills.interactionUsability.patternIdentification,
        arquiteturaDeInformacao:
          user.uxSkills.interactionUsability.informationArchitecture,
        mapeamentoDeFluxo: user.uxSkills.interactionUsability.flowMapping,
        acessibilidade: user.uxSkills.interactionUsability.accessibility
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updateDigitalProduct = async (
  user: User
): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/conhecimento-produto-digital`,
      {
        design: user.uxSkills.digitalProduct.design,
        documentacao: user.uxSkills.digitalProduct.documentation,
        compreenderLimitacoesTecnicas:
          user.uxSkills.digitalProduct.understandTechnicalLimitations,
        benchmarkVisual: user.uxSkills.digitalProduct.visualBenchmark,
        prototipacao: user.uxSkills.digitalProduct.prototyping,
        animacoes: user.uxSkills.digitalProduct.animations,
        designSystem: user.uxSkills.digitalProduct.designSystem,
        organizacaoDeWorkflow: user.uxSkills.digitalProduct.workflowOrganization
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};

export const updateUxWriting = async (user: User): Promise<Response<User>> => {
  try {
    const response = await api.post(
      `/users/${onlyNumbers(user.document!)}/conhecimento-ux-writing`,
      {
        comunicacao: user.uxSkills.uxWriting.communication,
        redacao: user.uxSkills.uxWriting.redaction,
        microcopy: user.uxSkills.uxWriting.microcopy,
        usabilidade: user.uxSkills.uxWriting.usability,
        taxonomia: user.uxSkills.uxWriting.taxonomy
      }
    );

    return Response.fromData(
      User.fromJson(response.data.dados),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};
