import { format } from 'date-fns';
import { cpfMask, phoneMask } from 'core/mask';
import Address from './Address';
import SocialMedia from './SocialMedia';
import Accepted from './Accepted';
import Privacy from './Privacy';
import UxSkills from './UxSkills';
import UxExperience from './UxExperience';
import PersonalInformation from './PersonalInformation';
import ProfessionalExperience from './ProfessionalExperience';
import Education from './Education';
import Photo from './Photo';

class User {
  id?: string;

  email?: string;

  createdAt?: Date;

  document?: string;

  birthDate?: string;

  name?: string;

  cellphone?: string;

  address: Address;

  socialMedia: SocialMedia;

  accepted: Accepted;

  privacy?: Privacy;

  uxSkills: UxSkills;

  uxExperience: UxExperience;

  personalInformation: PersonalInformation;

  professionalExperience: ProfessionalExperience;

  education: Education;

  photo: Photo;

  constructor() {
    this.address = new Address();
    this.socialMedia = new SocialMedia();
    this.accepted = new Accepted();
    this.uxSkills = new UxSkills();
    this.uxExperience = new UxExperience();
    this.personalInformation = new PersonalInformation();
    this.professionalExperience = new ProfessionalExperience();
    this.education = new Education();
    this.photo = new Photo();
  }

  static fromJson(data: any): User {
    const privacyMap: Record<string, Privacy> = {
      Invisivel: Privacy.INVISIBLE,
      Publico: Privacy.PUBLIC,
      BuscandoOportunidade: Privacy.SEEKING_OPPORTUNITY
    };

    const user = new User();
    user.email = data.email;
    user.createdAt = new Date(data.dataCriacao);
    user.document = cpfMask(data.documento);
    user.name = data.nome;
    user.cellphone = phoneMask(data.celular);
    user.birthDate =
      data.dataNascimento &&
      format(new Date(data.dataNascimento), 'yyyy-MM-dd');
    user.address = Address.fromJson(data.endereco || {});
    user.socialMedia = SocialMedia.fromJson(
      data.redesSociais || {},
      !!data.redesSociais
    );
    user.accepted = Accepted.fromJson(data.aceites || {});
    user.uxSkills = UxSkills.fromJson(data.conhecimentos || {});
    user.uxExperience = UxExperience.fromJson(data.experienciaUX || {});
    user.personalInformation = PersonalInformation.fromJson(data);
    user.professionalExperience = ProfessionalExperience.fromJson(
      data.experienciaProfissional || {}
    );
    user.education = Education.fromJson(data.educacao || {});
    user.photo = Photo.fromJson(data.photo || {});
    user.privacy = privacyMap[data.privacidade];

    return user;
  }
}

export default User;
