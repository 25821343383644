import React from 'react';
import { Input, Button, useAlert, Link } from '@uxparaminaspretas/components';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';

import { login } from 'services/authenticate';
import useForm from 'hooks/useForm';

import AuthNavbar from 'components/AuthNavbar';
import { SigninForm, Title, Buttons, Fields } from './styles';

const SigninSchema = yup.object().shape({
  document: yup.string().required().length(11),
  password: yup.string().required()
});

const SignIn: React.FC = () => {
  const alert = useAlert();
  const history = useHistory();

  const { setField, errors, handleSubmit, loading } = useForm(
    { document: '', password: '' },
    SigninSchema,
    async (fields) => {
      const result = await login(
        fields.document.replace(/[^\d]/g, ''),
        fields.password
      );

      if (result.data) {
        localStorage.setItem('@uxmp:token', result.data);
        history.push('/');
      } else {
        alert.error(
          'O dado fornecido está inválido. Verifique a informação digitada e tente novamente'
        );
      }
    }
  );

  return (
    <AuthNavbar>
      <SigninForm
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <Title textAlign="center" level={1}>
          Entrar
        </Title>
        <Fields>
          <Input
            name="document"
            label="CPF"
            onChange={(e) =>
              setField('document', e.target.value.replace(/[^\d]/g, ''))
            }
            placeholder="000.000.000-00"
            mask={[
              { length: 3, regexp: /^[0-9]{1,3}$/ },
              { fixed: '.' },
              { length: 3, regexp: /^[0-9]{1,3}$/ },
              { fixed: '.' },
              { length: 3, regexp: /^[0-9]{1,3}$/ },
              { fixed: '-' },
              { length: 2, regexp: /^[0-9]{1,2}$/ }
            ]}
            {...errors.document}
          />
          <Input
            name="password"
            type="password"
            label="Senha"
            onChange={(e) => setField('password', e.target.value)}
            {...errors.password}
          />
          <RouterLink to="/forgot-password">
            <Link size={12} label="Esqueci minha senha" />
          </RouterLink>
        </Fields>
        <Buttons>
          <Button loading={loading} type="submit" primary label="Entrar" />
          <RouterLink to="/signup">
            <Button secondary label="Criar conta" />
          </RouterLink>
        </Buttons>
      </SigninForm>
    </AuthNavbar>
  );
};

export default SignIn;
