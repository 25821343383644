import styled from 'styled-components';
import { Heading, Checkbox } from '@uxparaminaspretas/components';

export const SignupContainer = styled.main`
  max-width: 1200px;
  margin: 0 auto;
`;

export const SignupForm = styled.form`
  padding: 32px;
  width: 100%;
  flex: 1;
  overflow: auto;
`;

export const Title = styled(Heading)`
  text-align: center;
  margin-top: 32px;
  margin-bottom: 60px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 8%;
  gap: 112px;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    padding: 0;
  }
`;

export const LeftContainer = styled.section`
  flex: 1;

  & button {
    width: 100%;
  }
`;

export const RightContainer = styled.section`
  flex: 1;

  & button {
    width: 100%;
  }
`;

export const Fields = styled.section`
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

export const PasswordDisclaimers = styled.section`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  @media only screen and (max-width: 768px) {
    margin-bottom: 8px;
  }
`;

export const CheckboxContainer = styled.section`
  margin-bottom: 25px;

  @media only screen and (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 40px;
  }
`;

export const PrivacyPolicyLink = styled.a`
  padding-left: 5px;
  padding-right: 5px;
  text-decoration: underline;

  @media only screen and (max-width: 768px) {
    padding-left: 0;
    padding-right: 0;
  }
`;

export const CheckboxStyled = styled(Checkbox)`
  margin-bottom: 16px;
`;

export const PrivacyPolicy = styled.div`
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const ActionsButtonSection = styled.section`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 0;
`;
