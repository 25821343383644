import SkillLevel from './SkillLevel';
import UxSkills from './UxSkills';

class UxWriting {
  communication?: SkillLevel;

  redaction?: SkillLevel;

  microcopy?: SkillLevel;

  usability?: SkillLevel;

  taxonomy?: SkillLevel;

  static fromJson(data: any): UxWriting {
    const uxWriting = new UxWriting();
    uxWriting.communication = UxSkills.setSkillLevel(data.comunicacao);
    uxWriting.redaction = UxSkills.setSkillLevel(data.redacao);
    uxWriting.microcopy = UxSkills.setSkillLevel(data.microcopy);
    uxWriting.usability = UxSkills.setSkillLevel(data.usabilidade);
    uxWriting.taxonomy = UxSkills.setSkillLevel(data.taxonomia);

    return uxWriting;
  }
}

export default UxWriting;
