import styled from 'styled-components';

export const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & button {
    width: 100%;
  }
`;

export const PasswordValidateBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
