import * as yup from 'yup';
import { parse, isValid } from 'date-fns';
import Page from 'models/Page';

import { updateEducation } from 'services/user';
import Education from './Education';

const validateLocalDate = (month?: number, year?: number): boolean => {
  const date = `${year?.toString().padStart(4, '0')}-${month
    ?.toString()
    .padStart(2, '0')}-01`;

  return isValid(parse(date, 'yyyy-MM-dd', new Date()));
};

const educationPage: Page = {
  type: 'page',
  path: '/education',
  description: 'Configuração do perfil',
  title: 'Educação',
  subtitle:
    'Nos conte mais sobre sua formação acadêmica e outros cursos que tenha feito. Esses dados são importantes para encontrarmos vagas de acordo com o que você deseja.',
  icon: 'local_library',
  component: Education,
  validationSchema: yup.object().shape({
    education: yup.object().shape({
      institutions: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required(),
            current: yup.boolean(),
            degree: yup.string().required(),
            course: yup.string().required(),
            monthStartDate: yup
              .number()
              .required()
              .test('Date test', 'Esta data é inválida', function (value) {
                const year: number = this.resolve(yup.ref('yearStartDate'));

                return validateLocalDate(value, year);
              }),
            yearStartDate: yup.number().required(),
            monthEndDate: yup
              .number()
              .test('Date test', 'Esta data é inválida', function (value) {
                const year: number = this.resolve(yup.ref('yearEndDate'));

                if (value || year) {
                  return validateLocalDate(value, year);
                }

                return true;
              }),
            yearEndDate: yup.number()
          })
        )
        .min(1, 'Você deve incluir pelo menos uma intituição'),
      languages: yup.array().of(
        yup.object().shape({
          name: yup.string().required(),
          level: yup.string().required()
        })
      )
    })
  }),
  action: updateEducation
};

export default educationPage;
