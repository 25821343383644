import React from 'react';
import { Row, Col } from '@uxparaminaspretas/components';

import { useRegisterForm } from 'provider/RegisterFormProvider';
import Graphic from 'components/Graphic';

const ServiceDesign: React.FC = () => {
  const { fields, errors, setField } = useRegisterForm();

  return (
    <Row>
      <Col xs={12}>
        <Graphic
          label="Mapeamento da jornada do cliente"
          tooltipDescription="Unir as emoções e as expectativas que definem a perspectiva do cliente com a estrutura de interações possíveis que ele experimenta em seu processo de compra ou no uso de serviços."
          value={fields.uxSkills?.serviceDesign.customerJourneyMapping}
          onChange={(value) =>
            setField('uxSkills.serviceDesign.customerJourneyMapping', value)
          }
          {...errors['uxSkills.serviceDesign.customerJourneyMapping']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Storytelling"
          tooltipDescription="É a prática de se contar uma boa história, aplicada a serviços, produtos e experiências."
          value={fields.uxSkills?.serviceDesign.storytelling}
          onChange={(value) =>
            setField('uxSkills.serviceDesign.storytelling', value)
          }
          {...errors['uxSkills.serviceDesign.storytelling']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Inovação"
          tooltipDescription="Apresentar novas ideias, implementá-las com sucesso e crescer. É disso que se trata a inovação: transformar o que já existe em algo cada vez melhor."
          value={fields.uxSkills?.serviceDesign.innovation}
          onChange={(value) =>
            setField('uxSkills.serviceDesign.innovation', value)
          }
          {...errors['uxSkills.serviceDesign.innovation']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Organização da informação"
          tooltipDescription="Documentar todas as ideias que foram geradas durante um processo."
          value={fields.uxSkills?.serviceDesign.organizationOfInformation}
          onChange={(value) =>
            setField('uxSkills.serviceDesign.organizationOfInformation', value)
          }
          {...errors['uxSkills.serviceDesign.organizationOfInformation']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Organização de ideias / Geração de insights"
          tooltipDescription="Agrupamento e organização de ideias semelhantes ou opostas e suas conclusões."
          value={fields.uxSkills?.serviceDesign.organizationOfIdeas}
          onChange={(value) =>
            setField('uxSkills.serviceDesign.organizationOfIdeas', value)
          }
          {...errors['uxSkills.serviceDesign.organizationOfIdeas']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Facilitação e moderação de grupos"
          tooltipDescription="Em dinâmicas com outros colegas, coordenar as atividades realizadas."
          value={fields.uxSkills?.serviceDesign.groupModeration}
          onChange={(value) =>
            setField('uxSkills.serviceDesign.groupModeration', value)
          }
          {...errors['uxSkills.serviceDesign.groupModeration']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Blueprint do Serviço"
          tooltipDescription="Visão aprofundada do funcionamento de um serviço."
          value={fields.uxSkills?.serviceDesign.serviceBlueprint}
          onChange={(value) =>
            setField('uxSkills.serviceDesign.serviceBlueprint', value)
          }
          {...errors['uxSkills.serviceDesign.serviceBlueprint']}
        />
      </Col>
    </Row>
  );
};

export default ServiceDesign;
