import React, { useState } from 'react';
import {
  Row,
  Col,
  Select,
  Link as LinkDelete
} from '@uxparaminaspretas/components';
import { useInterval } from 'usehooks-ts';

import Language, { LanguageName, LanguageLevel } from 'models/Language';
import User from 'models/User';

import { ColStyled } from './styles';

const LanguagesNameMap = [
  { name: 'Português', value: LanguageName.PORTUGUES },
  { name: 'Inglês', value: LanguageName.INGLES },
  { name: 'Espanhol', value: LanguageName.ESPANHOL },
  { name: 'Alemão', value: LanguageName.ALEMAO },
  { name: 'Francês', value: LanguageName.FRANCES },
  { name: 'Italiano', value: LanguageName.ITALIANO },
  { name: 'Japonês', value: LanguageName.JAPONES },
  { name: 'Libras', value: LanguageName.LIBRAS },
  { name: 'Outro', value: LanguageName.OUTRO }
];

const LanguagesLevelMap = [
  { name: 'Básico', value: LanguageLevel.BASICO },
  { name: 'Intermediário', value: LanguageLevel.INTERMEDIARIO },
  { name: 'Avançado', value: LanguageLevel.AVANCADO },
  { name: 'Fluente', value: LanguageLevel.FLUENTE },
  { name: 'Nativo', value: LanguageLevel.NATIVO }
];

interface ILanguagesProps {
  fields: Partial<Language>;
  index: number;
  errors: Record<string, any>;
  onChange: (fields: Partial<User>) => void;
  onDelete: () => void;
}

const LanguagesForm: React.FC<ILanguagesProps> = ({
  fields: fieldsProps,
  index,
  onChange,
  errors,
  onDelete
}) => {
  const [fields, setFields] = useState(fieldsProps);

  useInterval(() => {
    onChange(fields);
  }, 1000);

  const setField = (name: keyof Language, value: any): void =>
    setFields({ ...fields, [name]: value });

  return (
    <Row key={index}>
      <Col xs={12} md={6}>
        <Select
          name="name"
          options={LanguagesNameMap}
          placeholder="Idioma"
          label="Selecione o idioma"
          labelKey="name"
          value={LanguagesNameMap.find((name) => name.value === fields.name)}
          onChange={({ option }) => setField('name', option.value)}
          {...errors[`education.language[${index}].name`]}
        />
      </Col>
      <ColStyled
        xs={12}
        md={6}
        className={fields.name === 'Outro' ? 'other' : ''}
      >
        {fields.name !== 'Outro' && (
          <Select
            name="level"
            options={LanguagesLevelMap}
            placeholder="Nível de conhecimento"
            label="Selecione o nível de conhecimento"
            labelKey="name"
            value={LanguagesLevelMap.find(
              (level) => level.value === fields.level
            )}
            onChange={({ option }) => setField('level', option.value)}
            {...errors[`education.language[${index}].level`]}
          />
        )}
      </ColStyled>

      <LinkDelete onClick={onDelete} label="remover idioma" icon="delete" />
    </Row>
  );
};

export default LanguagesForm;
