import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding-top: 100px;

  @media only screen and (max-width: 768px) {
    padding-top: 72px;
  }
`;
