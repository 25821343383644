import Page from 'models/Page';

import uxResearchSubpage from './UxResearch';
import serviceDesignSubpage from './ServiceDesign';
import interactionUsabilitySubpage from './InteractionUsability';
import digitalProductSubpage from './DigitalProduct';
import uxWritingSubpage from './UxWriting';

const uxSkillsPage: Page = {
  type: 'subpage',
  path: '/ux-skills',
  description: 'Configuração do perfil',
  title: 'Habilidades em UX',
  subtitle:
    'Queremos saber um pouco mais sobre seu conhecimento em UX mas, não se preocupe, pois, você não está sendo avaliada. Sua sinceridade será muito importante para oferecermos as melhores vagas, oportunidades de estudo e mentorias de acordo com seu perfil. ',
  icon: 'computer',
  subpages: [
    uxResearchSubpage,
    serviceDesignSubpage,
    interactionUsabilitySubpage,
    digitalProductSubpage,
    uxWritingSubpage
  ]
};

export default uxSkillsPage;
