import Page from 'models/Page';

import ChangePassword from './ChangePassword';

const changePasswordPage: Page = {
  type: 'page',
  path: '/change-password',
  description: 'Alterar senha',
  title: 'Alterar senha',
  icon: 'vpn_key',
  hiddenFlowButtons: true,
  component: ChangePassword
};

export default changePasswordPage;
