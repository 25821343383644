class Accepted {
  sms?: boolean;

  whatsapp?: boolean;

  telegram?: boolean;

  email?: boolean;

  privacy?: boolean;

  lgpd?: boolean;

  static fromJson(data: any): Accepted {
    const accepted = new Accepted();
    accepted.sms = data.sms;
    accepted.whatsapp = data.whatsapp;
    accepted.telegram = data.telegram;
    accepted.email = data.email;
    accepted.privacy = data.privacidade;
    accepted.lgpd = data.privacidade;

    return accepted;
  }
}

export default Accepted;
