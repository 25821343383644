import Institution from './Institution';
import Language from './Language';

class Education {
  institutions: Institution[];

  languages: Language[];

  constructor() {
    this.institutions = [];
    this.languages = [];
  }

  static fromJson(data: any): Education {
    const education = new Education();

    education.institutions =
      data.instituicoes?.map((item: any) => Institution.fromJson(item)) || [];

    if (education.institutions.length === 0) {
      education.institutions = [new Institution()];
    }

    return education;
  }
}

export default Education;
