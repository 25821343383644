import styled from 'styled-components';
import { Paragraph } from '@uxparaminaspretas/components';

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
`;

export const TitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const StyledParagraph = styled(Paragraph)`
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 140%;
  }
`;
