import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { ValidationError } from 'yup';
import { Divider, Button, useAlert } from '@uxparaminaspretas/components';

import { useFields, useSubmit } from 'provider/RegisterFormProvider';
import { useAuth } from 'provider/AuthProvider';
import { PageWithoutSubpage, SubPage } from 'models/Page';
import User from 'models/User';

import { FlowButtonsContainer } from './styles';

interface IPageRouter {
  page: PageWithoutSubpage | SubPage;
  hiddenFlowButtons?: boolean;
  previousPagePath?: string;
  nextPagePath?: string;
}

const FlowButtons: React.FC<IPageRouter> = ({
  page,
  hiddenFlowButtons = false,
  previousPagePath,
  nextPagePath
}) => {
  const { setUser } = useAuth();
  const { handleReset, setErrors } = useSubmit();
  const [loading, setLoading] = useState(false);
  const fields = useFields();
  const history = useHistory();
  const alert = useAlert();

  if (hiddenFlowButtons) {
    return <div />;
  }

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);

    if (page.action) {
      let isValid = false;

      if (page.validationSchema) {
        try {
          await page.validationSchema.validate(fields, { abortEarly: false });
          setErrors({});
          isValid = true;
        } catch (e) {
          const error = e as unknown as ValidationError;

          setErrors(
            error.inner.reduce(
              (acc, error) => ({
                ...acc,
                [error.path!]: { error: true, message: error.message }
              }),
              {}
            )
          );
          isValid = false;
        }
      }

      if (isValid) {
        const result = await page.action(fields as User);

        if (result.data) {
          setUser(result.data);

          history.push(nextPagePath!);
        } else {
          alert.error(
            'Não foi possível atualizar os dados do usuário. Por favor, tente novamente.'
          );
        }
      }
    }

    setLoading(false);
  };

  return (
    <>
      <Divider margin={40} size={1} />
      <FlowButtonsContainer>
        <div>
          {previousPagePath && (
            <Link to={previousPagePath}>
              <Button
                secondary
                size="small"
                label="Voltar"
                onClick={handleReset}
              />
            </Link>
          )}
        </div>
        {nextPagePath && (
          <Button
            primary
            size="small"
            loading={loading}
            label="Continuar"
            onClick={handleSubmit}
          />
        )}
      </FlowButtonsContainer>
    </>
  );
};

export default FlowButtons;
