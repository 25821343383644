import { Heading, Paragraph, Subtitle } from '@uxparaminaspretas/components';
import styled from 'styled-components';

export const DownloadSection = styled.section`
  display: flex;
  justify-content: flex-end;

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    margin-bottom: 40px;
  }
`;

export const PersonalInfoSection = styled.section`
  display: flex;
  margin-bottom: 72px;
  gap: 3%;
  align-items: center;

  @media only screen and (max-width: 768px) {
    margin-bottom: 40px;
  }
`;

export const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const SocialMediaContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const HeadingStyled = styled(Heading)`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 8px;
  margin-bottom: 18px;
  font-weight: 400;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const WorkPeriodContainer = styled.div`
  display: flex;
  gap: 10%;
  margin-top: 24px;
`;

export const ProfessionalExperienceSection = styled.section`
  margin-top: 40px;
`;

export const UxExperienceSection = styled.section`
  display: flex;
  flex-direction: row;

  & div {
    flex: 1;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
  }
`;

export const EducationSection = styled.section`
  margin-top: 40px;
`;

export const CustomParagraph = styled(Paragraph)`
  margin-top: 40px;
`;

export const SubtitleStyled = styled(Subtitle)`
  & + section {
    margin-top: 24px;
  }

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

export const ParagraphStyled = styled(Paragraph)`
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
