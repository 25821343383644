import React, { useEffect, useState } from 'react';
import { Redirect, Switch, useHistory, useLocation } from 'react-router-dom';

import { RegisterFormProvider } from 'provider/RegisterFormProvider';
import Navbar from 'components/Navbar';

import Sidebar from './containers/Sidebar';
import PageRouter from './containers/PageRouter';
import pages, { anotherPages } from './pages';
import { Main, Container, Root } from './styles';

const Home: React.FC = () => {
  const history = useHistory();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    if (location.pathname === '/') {
      history.push(pages[0].path);
    }

    setMenuOpen(false);
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <RegisterFormProvider>
      <Navbar mobile onMenuClick={() => setMenuOpen(true)} />
      <Main>
        <Sidebar menuOpen={menuOpen} onCloseMenu={() => setMenuOpen(false)} />
        <Root>
          <Container>
            <Switch>
              {pages.map((page, index) => (
                <PageRouter
                  key={page.path}
                  path={page.path}
                  exact={page.type === 'page'}
                  page={page}
                  previousPage={pages[index - 1]}
                  nextPage={pages[index + 1]}
                />
              ))}
              {anotherPages.map((page) => (
                <PageRouter
                  key={page.path}
                  path={page.path}
                  exact
                  page={page}
                />
              ))}
              <Redirect to="/404" />
            </Switch>
          </Container>
        </Root>
      </Main>
    </RegisterFormProvider>
  );
};

export default Home;
