import React, { useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import {
  Stepper,
  Divider,
  Description,
  Link,
  Icon,
  globalTokens
} from '@uxparaminaspretas/components';

import logo from 'assets/logo.svg';
import { useAuth } from 'provider/AuthProvider';
import { useFields } from 'provider/RegisterFormProvider';
import { isPageCompleted } from 'utils/isCompleted';

import pages from '../../pages';
import {
  Title,
  Logo,
  StyledSidebar,
  StyledLink,
  CloseButton,
  MarginTop
} from './styles';

interface ISidebarProps {
  menuOpen: boolean;
  onCloseMenu: () => void;
}

const pagesWithTimeline = pages.filter((item) => !item.hiddenTimeline);

const Sidebar: React.FC<ISidebarProps> = ({ menuOpen, onCloseMenu }) => {
  const fields = useFields();
  const location = useLocation();
  const history = useHistory();
  const { user, onLogout } = useAuth();
  const [completeds, setCompleteds] = useState<boolean[]>([]);
  const [current, setCurrent] = useState<number>(0);

  useEffect(() => {
    const readSteps = async (): Promise<void> => {
      const newCompleteds = await Promise.all(
        pagesWithTimeline.map((page) => isPageCompleted(page, fields))
      );
      setCompleteds(newCompleteds);

      const newCurrent = newCompleteds.findIndex((item) => item === false);
      setCurrent(newCurrent);

      if (newCurrent > -1) {
        const isDisabledUrl = pagesWithTimeline
          .filter((_, index) => index > newCurrent)
          .some((item) => item.path === `/${location.pathname.split('/')[1]}`);

        if (isDisabledUrl) {
          history.push(`${pagesWithTimeline[newCurrent].path}`);
        }
      }
    };

    readSteps();
  }, [user]);

  return (
    <>
      <StyledSidebar $menuOpen={menuOpen}>
        <Logo src={logo} alt="Logo da plataforma" />
        <Title>Configuração do perfil</Title>
        <CloseButton onClick={onCloseMenu}>
          <Icon icon="close" size={24} color={globalTokens.colors.neutral5} />
        </CloseButton>
        <Stepper
          inverse
          variant="vertical"
          steps={pagesWithTimeline.map((page, index) => {
            const isCurrent = index === current;
            const completed = completeds[index];
            const nonCompleted = !completed || isCurrent;

            const data = {
              disabled: !completed && !isCurrent,
              title: page.title,
              selected:
                location.pathname.split('/')[1] === page.path.replace('/', ''),
              props: { $page: page }
            };

            if (completed) {
              return { ...data, status: 'success' };
            }
            if (isCurrent) {
              return { ...data, icon: page.icon };
            }
            if (nonCompleted) {
              return { ...data, icon: page.icon, status: 'unchecked' };
            }
            return data;
          })}
          onStepClick={(step) => history.push(step.props.$page.path)}
        />
        <MarginTop>
          <StyledLink to="/change-password">
            <Description>Alterar senha</Description>
          </StyledLink>
        </MarginTop>
        <Divider inverse size={2} margin={24} />
        <StyledLink to="/profile-privacy">
          <Description>Escolher privacidade do perfil</Description>
        </StyledLink>

        <Divider inverse size={2} margin={24} />
        <Link onClick={onLogout} inverse label="Sair" icon="exit_to_app" />
      </StyledSidebar>
    </>
  );
};

export default Sidebar;
