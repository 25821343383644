import Page from 'models/Page';

import ProfilePrivacy from './ProfilePrivacy';

const profilePrivacyPage: Page = {
  type: 'page',
  path: '/profile-privacy',
  description: 'Meus dados',
  title: 'Privacidade do perfil',
  icon: 'security',
  hiddenFlowButtons: true,
  component: ProfilePrivacy,
  hiddenTimeline: true
};

export default profilePrivacyPage;
