import * as yup from 'yup';

yup.setLocale({
  mixed: { required: 'Este campo é obrigatório.' },
  string: {
    length: 'Este campo deve ter ${length} caracteres.',
    url: 'Esta url é inválida.',
    email: 'Este email é inválido.'
  },
  array: { min: 'Este campo é obrigatório.' }
});
