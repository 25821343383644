import { useContext } from 'react';

import RegisterFormContext from './Context';
import { IErrors, UserNestedObjectLeaves } from './IRegisterFormProps';

interface IUseSubmitProps {
  handleReset: () => void;
  setErrors: (
    errors: Partial<{ [K in UserNestedObjectLeaves]: IErrors }>
  ) => void;
}

function useSubmit(): IUseSubmitProps {
  const context = useContext(RegisterFormContext);

  return {
    setErrors: context!.setErrors,
    handleReset: context!.handleReset
  };
}

export default useSubmit;
