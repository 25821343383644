import SkillLevel from './SkillLevel';
import UxSkills from './UxSkills';

class DigitalProduct {
  design?: SkillLevel;

  documentation?: SkillLevel;

  understandTechnicalLimitations?: SkillLevel;

  visualBenchmark?: SkillLevel;

  prototyping?: SkillLevel;

  animations?: SkillLevel;

  designSystem?: SkillLevel;

  workflowOrganization?: SkillLevel;

  static fromJson(data: any): DigitalProduct {
    const digitalProduct = new DigitalProduct();
    digitalProduct.design = UxSkills.setSkillLevel(data.design);
    digitalProduct.documentation = UxSkills.setSkillLevel(data.documentacao);
    digitalProduct.understandTechnicalLimitations = UxSkills.setSkillLevel(
      data.compreenderLimitacoesTecnicas
    );
    digitalProduct.visualBenchmark = UxSkills.setSkillLevel(
      data.benchmarkVisual
    );
    digitalProduct.prototyping = UxSkills.setSkillLevel(data.prototipacao);
    digitalProduct.animations = UxSkills.setSkillLevel(data.animacoes);
    digitalProduct.designSystem = UxSkills.setSkillLevel(data.designSystem);
    digitalProduct.workflowOrganization = UxSkills.setSkillLevel(
      data.organizacaoDeWorkflow
    );

    return digitalProduct;
  }
}

export default DigitalProduct;
