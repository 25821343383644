import { v4 as uuidv4 } from 'uuid';

export enum Degree {
  ENSINO_FUNDAMENTAL = 'EnsinoFundamental',
  ENSINO_MEDIO = 'EnsinoMedio',
  ENSINO_TECNICO = 'EnsinoTecnico',
  GRADUACAO = 'Graduacao',
  POS_GRADUACAO = 'PosGraduacao',
  MESTRADO = 'Mestrado',
  DOUTORADO = 'Doutorado',
  CURSO_COMPLEMENTAR = 'CursoComplementar'
}

class Institution {
  id: string;

  name?: string;

  degree?: Degree;

  course?: string;

  monthStartDate?: number;

  yearStartDate?: number;

  monthEndDate?: number;

  yearEndDate?: number;

  constructor() {
    this.id = uuidv4();
  }

  static fromJson(data: any): Institution {
    const institution = new Institution();
    institution.name = data.nome;
    institution.degree = data.diploma;
    institution.course = data.curso;

    const { month: monthStartDate, year: yearStartDate } = Institution.readDate(
      data.dataInicio
    );
    institution.monthStartDate = monthStartDate;
    institution.yearStartDate = yearStartDate;

    if (data.dataFinal) {
      const { month: monthEndDate, year: yearEndDate } = Institution.readDate(
        data.dataFinal
      );
      institution.monthEndDate = monthEndDate;
      institution.yearEndDate = yearEndDate;
    }

    return institution;
  }

  static readDate(date: string): { month: number; year: number } {
    const dateSplit = date.split('-');

    return { month: Number(dateSplit[1]), year: Number(dateSplit[2]) };
  }
}

export default Institution;
