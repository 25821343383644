import React, { useState } from 'react';
import {
  Row,
  Col,
  Radio,
  Button,
  Paragraph,
  Modal,
  useAlert,
  Avatar,
  Input
} from '@uxparaminaspretas/components';
import { useHistory } from 'react-router-dom';

import Privacy from 'models/Privacy';
import User from 'models/User';
import { useRegisterForm } from 'provider/RegisterFormProvider';
import { updatePhoto, updatePrivacy } from 'services/user';
import convertBase64 from 'utils/convertBase64';

import { StyledParagraph, ButtonContent, AvatarContent } from './styles';

const ProfilePrivacy: React.FC = () => {
  const history = useHistory();
  const { fields, setField, setFields } = useRegisterForm();
  const [loading, setLoading] = useState(false);
  const [modalShow, setModalShow] = useState(
    !fields.photo?.url && !fields.photo?.content
  );
  const [avatarLoading, setAvatarLoading] = useState(false);
  const alert = useAlert();

  const handleSubmit = async (): Promise<void> => {
    setLoading(true);

    const result = await updatePrivacy(fields);

    if (result.data) {
      alert.success('Privacidade atualizada com sucesso.');
      history.push('/my-profile');
    } else {
      alert.error(
        'Não foi possível atualizar a privacidade. Por favor, tente novamente.'
      );
    }

    setLoading(false);
  };

  const handleAvatarSubmit = async (): Promise<void> => {
    setAvatarLoading(true);

    const result = await updatePhoto(fields as User);

    if (result.data) {
      alert.success('Foto atualizada com sucesso.');
      setModalShow(false);
    } else {
      alert.error('Foto obrigatória.');
    }

    setAvatarLoading(false);
  };

  const readImage = async (file?: File | null): Promise<void> => {
    if (!file) {
      return;
    }

    const fileContent = await convertBase64(file);

    setFields({
      'photo.content': fileContent,
      'photo.mime': file.type,
      'photo.name': file.name
    });
  };

  return (
    <>
      <Row>
        <Col xs={12}>
          <Radio
            label="Visibilidade pública do seu perfil"
            value="public"
            checked={fields.privacy === Privacy.PUBLIC}
            onChange={() => setField('privacy', Privacy.PUBLIC)}
          />
          <StyledParagraph>
            Você tem o controle de tornar público o seu perfil sempre que
            desejar. Ao tornar público, todas as informações exibidas nesta
            página ficaram visíveis para toda a comunidade UX para Minas Pretas.
          </StyledParagraph>
        </Col>
        <Col xs={12}>
          <Radio
            label="Visibilidade para busca de novas oportunidades"
            value="opportunities"
            checked={fields.privacy === Privacy.SEEKING_OPPORTUNITY}
            onChange={() => setField('privacy', Privacy.SEEKING_OPPORTUNITY)}
          />
          <StyledParagraph>
            Ao selecionar essa opção, seu perfil ficará visível para que as
            empresas possam encontrá-la e também entrar em contato com você,
            além de tornar visível seus dados para a comunidade UX Minas Pretas.
          </StyledParagraph>
        </Col>
        <Col xs={12}>
          <Radio
            label="Invisível"
            value="invisible"
            checked={fields.privacy === Privacy.INVISIBLE}
            onChange={() => setField('privacy', Privacy.INVISIBLE)}
          />
          <StyledParagraph>
            Apenas as colaboradoras do UX para Minas Pretas terão acesso aos
            seus dados. Comunidade e as empresas não poderão ver seus dados
          </StyledParagraph>
        </Col>
        <Col xs={12}>
          <ButtonContent>
            <Button
              loading={loading}
              primary
              label="Salvar"
              onClick={handleSubmit}
            />
          </ButtonContent>
        </Col>
      </Row>
      <Modal
        show={modalShow}
        title="Foto do perfil"
        actions={
          <>
            <Button
              primary
              label="Salvar"
              onClick={handleAvatarSubmit}
              loading={avatarLoading}
            />
          </>
        }
      >
        <Paragraph style={{ marginBottom: 0 }}>
          <AvatarContent>
            <Avatar size="150px" src={fields.photo?.getPhoto()} />
            <Input
              type="file"
              name="name"
              label="Adicionar foto"
              id="upload-photo"
              accept="image/*"
              onChange={(e) => readImage(e.target.files?.item(0))}
            />
            <Paragraph>
              Para finalizar o cadastro e deixar seu perfil mais completo
              solicitamos que você inclua uma foto de sua preferência.
            </Paragraph>
          </AvatarContent>
        </Paragraph>
      </Modal>
    </>
  );
};

export default ProfilePrivacy;
