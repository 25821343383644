import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Spinner } from '@uxparaminaspretas/components';

import { readCurrentDetails } from 'services/authenticate';
import User from 'models/User';

import AuthContext from './Context';
import { Container } from './styles';

const AuthProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState<User>();

  const getData = async (): Promise<void> => {
    setLoading(true);

    const response = await readCurrentDetails();

    if (response.data) {
      setUser(response.data);
    } else {
      localStorage.removeItem('@uxmp:token');
      history.push('/signin');
    }

    setLoading(false);
  };

  useEffect(() => {
    const token = localStorage.getItem('@uxmp:token');

    if (token) {
      getData();
    } else {
      setLoading(false);
      history.push('/signin');
    }
  }, []);

  const propsProvider = useMemo(
    () => ({
      user: user!,
      setUser,
      onLogout: () => {
        localStorage.removeItem('@uxmp:token');
        history.push('/signin');
      }
    }),
    [user]
  );

  return (
    <AuthContext.Provider value={propsProvider}>
      {loading ? (
        <Container>
          <Spinner size="large" />
        </Container>
      ) : (
        children
      )}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
