import * as yup from 'yup';
import { SubPage } from 'models/Page';
import { updateServiceDesign } from 'services/uxSkills';
import ServiceDesign from './ServiceDesign';

const serviceDesignSubpage: SubPage = {
  title: 'Design de serviços',
  path: '/service-design',
  component: ServiceDesign,
  tooltip: {
    title: 'Design de Serviços',
    description:
      'É a ponte entre a visão do usuário e da companhia. A prática é feita através de pesquisas qualitativas, quantitativas e do compartilhamento de descobertas, com o objetivo de melhorar a experiência de usuários.'
  },
  validationSchema: yup.object().shape({
    uxSkills: yup.object().shape({
      serviceDesign: yup.object().shape({
        customerJourneyMapping: yup.string().required(),
        storytelling: yup.string().required(),
        innovation: yup.string().required(),
        organizationOfInformation: yup.string().required(),
        organizationOfIdeas: yup.string().required(),
        groupModeration: yup.string().required(),
        serviceBlueprint: yup.string().required()
      })
    })
  }),
  action: updateServiceDesign
};

export default serviceDesignSubpage;
