import SkillLevel from './SkillLevel';
import UxSkills from './UxSkills';

class ServiceDesign {
  customerJourneyMapping?: SkillLevel;

  storytelling?: SkillLevel;

  innovation?: SkillLevel;

  organizationOfInformation?: SkillLevel;

  organizationOfIdeas?: SkillLevel;

  groupModeration?: SkillLevel;

  serviceBlueprint?: SkillLevel;

  static fromJson(data: any): ServiceDesign {
    const serviceDesign = new ServiceDesign();
    serviceDesign.customerJourneyMapping = UxSkills.setSkillLevel(
      data.mapeamentoDaJornadaDoCliente
    );
    serviceDesign.storytelling = UxSkills.setSkillLevel(data.storytelling);
    serviceDesign.innovation = UxSkills.setSkillLevel(data.inovacao);
    serviceDesign.organizationOfInformation = UxSkills.setSkillLevel(
      data.organizacaoDaInformacao
    );
    serviceDesign.organizationOfIdeas = UxSkills.setSkillLevel(
      data.organizacaoDeIdeias
    );
    serviceDesign.groupModeration = UxSkills.setSkillLevel(
      data.moderacaoDeGrupos
    );
    serviceDesign.serviceBlueprint = UxSkills.setSkillLevel(
      data.blueprintDoServico
    );

    return serviceDesign;
  }
}

export default ServiceDesign;
