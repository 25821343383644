import Page from 'models/Page';
import pages from 'pages/Home/pages';
import { isPageCompleted } from 'utils/isCompleted';

import MyProfile from './MyProfile';

const myProfilePage: Page = {
  type: 'page',
  path: '/my-profile',
  description: 'Configuração do perfil',
  title: 'Meu perfil',
  icon: 'person',
  component: MyProfile,
  completed: async (fields) => {
    const isCompleted = await Promise.all(
      pages
        .slice(0, pages.length - 2)
        .map((page) => isPageCompleted(page, fields))
    );

    return isCompleted.every((item) => item);
  }
};

export default myProfilePage;
