import { ObjectSchema } from 'yup';
import Page, { SubPage } from 'models/Page';
import User from 'models/User';

const isValid = async (
  validationSchema: ObjectSchema<any>,
  fields: Partial<User>
): Promise<boolean> => {
  try {
    return await validationSchema.isValid(fields, { abortEarly: false });
  } catch (e) {
    return false;
  }
};

export const isPageCompleted = async (
  page: Page,
  fields: Partial<User>
): Promise<boolean> => {
  if (page.type === 'page' && page.completed) {
    return page.completed(fields);
  }

  if (page.type === 'page' && page.validationSchema) {
    return isValid(page.validationSchema, fields);
  }

  if (page.type === 'subpage') {
    const isCompleted = await Promise.all(
      page.subpages.map((subpage) => isSubpageCompleted(subpage, fields))
    );

    return isCompleted.every((item) => item);
  }

  return false;
};

export const isSubpageCompleted = async (
  subpage: SubPage,
  fields: Partial<User>
): Promise<boolean> => {
  if (subpage.completed) {
    return subpage.completed(fields);
  }

  if (subpage.validationSchema) {
    return isValid(subpage.validationSchema, fields);
  }

  return false;
};
