import Page from 'models/Page';

import contactDetailsPage from 'pages/ContactDetails';
import personalInformationPage from 'pages/PersonalInformation';
import educationPage from 'pages/Education';
import uxExperiencePage from 'pages/UxExperience';
import professionalExperiencePage from 'pages/ProfessionalExperience';
import uxSkillsPage from 'pages/UxSkills';
import myProfilePage from 'pages/MyProfile';
import profilePrivacyPage from 'pages/ProfilePrivacy';
import changePasswordPage from 'pages/ChangePassword';

const pages: Page[] = [
  contactDetailsPage,
  personalInformationPage,
  educationPage,
  uxExperiencePage,
  professionalExperiencePage,
  uxSkillsPage,
  profilePrivacyPage,
  myProfilePage
];

export const anotherPages: Page[] = [profilePrivacyPage, changePasswordPage];

export default pages;
