import React, { useState } from 'react';
import {
  Row,
  Col,
  Hidden,
  Input,
  Select,
  Link as LinkDelete
} from '@uxparaminaspretas/components';
import { useInterval } from 'usehooks-ts';

import Institution, { Degree } from 'models/Institution';
import User from 'models/User';

import {
  validateMonthEndDate,
  validateMonthStartDate
} from 'utils/validateMonthsDate';
import {
  Container,
  DateContainer,
  DateLabel,
  DeleteInstitutionSection
} from './styles';

const DegreeMap = [
  { name: 'Ensino Fundamental', value: Degree.ENSINO_FUNDAMENTAL },
  { name: 'Ensino Médio', value: Degree.ENSINO_MEDIO },
  { name: 'Ensino Técnico', value: Degree.ENSINO_TECNICO },
  { name: 'Graduação', value: Degree.GRADUACAO },
  { name: 'Pós Graduação / Especialização', value: Degree.POS_GRADUACAO },
  { name: 'Mestrado', value: Degree.MESTRADO },
  { name: 'Doutorado', value: Degree.DOUTORADO },
  { name: 'Curso Complementar', value: Degree.CURSO_COMPLEMENTAR }
];

interface IInstitutionProps {
  fields: Partial<Institution>;
  index: number;
  errors: Record<string, any>;
  onChange: (fields: Partial<User>) => void;
  onDelete: () => void;
}

const InstitutionForm: React.FC<IInstitutionProps> = ({
  fields: fieldsProps,
  index,
  onChange,
  errors,
  onDelete
}) => {
  const [fields, setFields] = useState(fieldsProps);

  useInterval(() => {
    onChange(fields);
  }, 1000);

  const setField = (name: keyof Institution, value: any): void =>
    setFields({ ...fields, [name]: value });

  const setNumberField = (name: keyof Institution, value: any): void =>
    setFields({
      ...fields,
      [name]: value.replace(/\D/g, '') ? Number(value.replace(/\D/g, '')) : ''
    });

  const startDateError =
    errors[`education.institutions[${index}].monthStartDate`]?.error ||
    errors[`education.institutions[${index}].yearStartDate`]?.error;

  const endDateError =
    errors[`education.institutions[${index}].monthEndDate`]?.error ||
    errors[`education.institutions[${index}].yearEndDate`]?.error;

  return (
    <Container>
      <DeleteInstitutionSection>
        <LinkDelete
          onClick={onDelete}
          label="remover instituição"
          icon="delete"
        />
      </DeleteInstitutionSection>
      <Row key={index}>
        <Col xs={12} md={6}>
          <Input
            name="name"
            label="Nome da Instituição de ensino"
            placeholder="Nome da Instituição"
            value={fields.name}
            onChange={(e) => setField('name', e.target.value)}
            {...errors[`education.institutions[${index}].name`]}
          />
        </Col>
        <Hidden xs sm>
          <Col xs={12} md={6} />
        </Hidden>
        <Col xs={12} md={6}>
          <Input
            name="course"
            label="Nome do curso"
            placeholder="ex: Design Gráfico"
            value={fields.course}
            onChange={(e) => setField('course', e.target.value)}
            {...errors[`education.institutions[${index}].course`]}
          />
        </Col>
        <Hidden xs sm>
          <Col xs={12} md={6} />
        </Hidden>
        <Col xs={12} md={6}>
          <Select
            name="degree"
            options={DegreeMap}
            placeholder="Tipo de curso"
            label="Selecione o tipo de curso"
            labelKey="name"
            value={DegreeMap.find((degree) => degree.value === fields.degree)}
            onChange={({ option }) => setField('degree', option.value)}
            {...errors[`education.institutions[${index}].course`]}
          />
        </Col>
        <Hidden xs sm>
          <Col xs={12} md={6} />
        </Hidden>
        <Col xs={12} md={4}>
          <DateLabel>Data de início</DateLabel>
          <DateContainer>
            <Input
              name="monthStartDate"
              placeholder="Mês (ex. 04)"
              value={validateMonthStartDate(fields)}
              onChange={(e) => setNumberField('monthStartDate', e.target.value)}
              error={startDateError}
            />
            <Input
              name="yearStartDate"
              placeholder="Ano (ex. 2004)"
              value={fields.yearStartDate}
              onChange={(e) => setNumberField('yearStartDate', e.target.value)}
              error={startDateError}
            />
          </DateContainer>
        </Col>
        <Col xs={12} md={4}>
          <DateLabel>Data de término</DateLabel>
          <DateContainer>
            <Input
              name="monthEndDate"
              placeholder="Mês (ex. 04)"
              value={validateMonthEndDate(fields)}
              onChange={(e) => setNumberField('monthEndDate', e.target.value)}
              error={endDateError}
            />
            <Input
              name="yearEndDate"
              placeholder="Ano (ex. 2004)"
              value={fields.yearEndDate}
              onChange={(e) => setNumberField('yearEndDate', e.target.value)}
              error={endDateError}
            />
          </DateContainer>
        </Col>
      </Row>
    </Container>
  );
};

export default InstitutionForm;
