import React from 'react';
import { Route } from 'react-router-dom';
import {
  Divider,
  Tooltip,
  Icon,
  Heading,
  globalTokens
} from '@uxparaminaspretas/components';

import Page, { SubPage, PageWithSubpage } from 'models/Page';
import FlowButtons from '../FlowButtons';

import { TitleContainer, TitleContent, StyledParagraph } from './styles';

interface ISubpageRouter {
  path: string;
  exact: boolean;
  subpage: SubPage;
  page: PageWithSubpage;
  previousSubpage?: SubPage;
  nextSubpage?: SubPage;
  previousPage?: Page;
  nextPage?: Page;
}

const SubpageRouter: React.FC<ISubpageRouter> = ({
  path,
  page,
  subpage,
  previousSubpage,
  nextSubpage,
  previousPage,
  nextPage
}) => (
  <Route exact path={path}>
    <TitleContainer>
      <TitleContent>
        <Heading level={3}>{subpage.title}</Heading>
        {subpage.tooltip && (
          <Tooltip {...subpage.tooltip}>
            <Icon size={24} icon="info" color={globalTokens.colors.neutral4} />
          </Tooltip>
        )}
      </TitleContent>
      <StyledParagraph>{subpage.subtitle}</StyledParagraph>
    </TitleContainer>
    <Divider margin={24} size={1} />
    <subpage.component />
    <FlowButtons
      page={subpage}
      previousPagePath={
        (previousSubpage && `${page.path}${previousSubpage?.path}`) ||
        previousPage?.path
      }
      nextPagePath={
        (nextSubpage && `${page.path}${nextSubpage?.path}`) || nextPage?.path
      }
    />
  </Route>
);

export default SubpageRouter;
