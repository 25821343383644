import * as yup from 'yup';
import { parse, isValid } from 'date-fns';
import Page from 'models/Page';
import { updateProfessionalExperience } from 'services/user';
import ProfessionalExperience from './ProfessionalExperience';

const validateLocalDate = (month?: number, year?: number): boolean => {
  const date = `${year?.toString().padStart(4, '0')}-${month
    ?.toString()
    .padStart(2, '0')}-01`;

  return isValid(parse(date, 'yyyy-MM-dd', new Date()));
};

const professionalExperiencePage: Page = {
  type: 'page',
  path: '/professional-experience',
  description: 'Configuração do perfil',
  title: 'Experiência profissional',
  subtitle:
    'Nos conte mais sobre suas experiências profissionais. Esses dados são importantes para no futuro ajudarmos você a encontrar vagas de acordo com o que você deseja.',
  icon: 'work',
  component: ProfessionalExperience,
  validationSchema: yup.object().shape({
    professionalExperience: yup.object().shape({
      summary: yup.string().required(),
      companies: yup
        .array()
        .of(
          yup.object().shape({
            name: yup.string().required(),
            current: yup.boolean(),
            position: yup.string().required(),
            type: yup.string().required(),
            monthStartDate: yup
              .number()
              .required()
              .test('Date test', 'Esta data é inválida', function (value) {
                const year: number = this.resolve(yup.ref('yearStartDate'));

                return validateLocalDate(value, year);
              }),
            yearStartDate: yup.number().required(),
            monthEndDate: yup
              .number()
              .when('current', {
                is: (current: boolean) => !current,
                then: yup.number().required()
              })
              .test('Date test', 'Esta data é inválida', function (value) {
                const year: number = this.resolve(yup.ref('yearEndDate'));
                const current: boolean = this.resolve(yup.ref('current'));

                return current || validateLocalDate(value, year);
              }),
            yearEndDate: yup.number().when('current', {
              is: (current: boolean) => !current,
              then: yup.number().required()
            }),
            activities: yup.string(),
            uxmpHelp: yup.string()
            // uxmpGroups: yup.boolean().required(),
            // uxmpIndication: yup.boolean().required()
          })
        )
        .min(1, 'Você deve incluir pelo menos uma empresa')
    })
  }),
  action: updateProfessionalExperience
};

export default professionalExperiencePage;
