export enum SocialMediaGroups {
  FACEBOOK = 'facebook',
  SLACK = 'slack'
}

class SocialMedia {
  isCompleted?: boolean;

  facebook?: string;

  instagram?: string;

  linkedin?: string;

  telegram?: string;

  slack?: string;

  groups?: SocialMediaGroups[];

  static fromJson(data: any, isCompleted: boolean): SocialMedia {
    const socialMedia = new SocialMedia();
    socialMedia.facebook = data.facebook;
    socialMedia.instagram = data.instagram;
    socialMedia.linkedin = data.linkedin;
    socialMedia.telegram = data.telegram;
    socialMedia.slack = data.slack;
    const groups = [];
    if (data.grupos) {
      if (data.grupos.facebook) groups.push(SocialMediaGroups.FACEBOOK);
      if (data.grupos.slack) groups.push(SocialMediaGroups.SLACK);
    }
    socialMedia.groups = groups;
    socialMedia.isCompleted = isCompleted;

    return socialMedia;
  }
}

export default SocialMedia;
