import styled from 'styled-components';
import { Heading } from '@uxparaminaspretas/components';

export const SigninForm = styled.form`
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 432px;
  max-width: 100%;
  justify-content: center;
  flex: 1;
  overflow: auto;

  & button {
    width: 100%;
  }
`;

export const Title = styled(Heading)`
  text-align: center;
  margin-bottom: 60px;
`;

export const Buttons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  gap: 8px;

  & > div:nth-child(1) {
    margin-bottom: 16px;
  }
`;
