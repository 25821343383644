import React, { useState } from 'react';

import User from 'models/User';
import { useAuth } from 'provider/AuthProvider';

import IRegisterFormProps, { IErrors } from './IRegisterFormProps';
import RegisterFormContext from './Context';

const RegisterFormProvider: React.FC = ({ children }) => {
  const { user } = useAuth();
  const [fields, setFields] = useState(user);
  const [errors, setErrors] = useState<Partial<{ [K in keyof User]: IErrors }>>(
    {}
  );

  const propsProvider: IRegisterFormProps = {
    fields,
    errors,
    setFields: (newFields) => setFields(newFields),
    setErrors: (newErrors) => setErrors(newErrors),
    handleReset: (): void => setFields(user)
  };

  return (
    <RegisterFormContext.Provider value={propsProvider}>
      {children}
    </RegisterFormContext.Provider>
  );
};

export default RegisterFormProvider;
