import * as yup from 'yup';
import { SubPage } from 'models/Page';
import { updateAddress } from 'services/user';
import Address from './Address';

const addressSubpage: SubPage = {
  title: 'Endereço',
  path: '/address',
  component: Address,
  validationSchema: yup.object().shape({
    address: yup.object().shape({
      zipCode: yup.string().required(),
      street: yup.string().required(),
      number: yup.string().required(),
      complement: yup.string(),
      city: yup.string().required(),
      state: yup.string().required()
    })
  }),
  action: updateAddress
};

export default addressSubpage;
