import api from 'axios';
import Response from 'models/Response';
import Address from 'models/Address';

export const readAddress = async (
  zipCode: string
): Promise<Response<Address>> => {
  try {
    const response = await api.get(`https://viacep.com.br/ws/${zipCode}/json/`);

    return Response.fromData(
      Address.fromCepJson(response.data),
      response.status
    );
  } catch (e: any) {
    return Response.fromError(e);
  }
};
