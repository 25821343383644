import React from 'react';
import { Row, Col } from '@uxparaminaspretas/components';

import { useRegisterForm } from 'provider/RegisterFormProvider';
import Graphic from 'components/Graphic';

const InteractionUsability: React.FC = () => {
  const { fields, errors, setField } = useRegisterForm();

  return (
    <Row>
      <Col xs={12}>
        <Graphic
          label="Princípios de usabilidade"
          tooltipDescription="Também chamados de Heurísticas, são conselhos de especialistas a respeito de boas práticas no design de interfaces com usuários."
          value={fields.uxSkills?.interactionUsability.usabilityPrinciples}
          onChange={(value) =>
            setField('uxSkills.interactionUsability.usabilityPrinciples', value)
          }
          {...errors['uxSkills.interactionUsability.usabilityPrinciples']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Abordagens centradas no ser humano"
          tooltipDescription="É o ponto de vista que tem o ser humano como centro, como usuário."
          value={fields.uxSkills?.interactionUsability.humanCenteredApproaches}
          onChange={(value) =>
            setField(
              'uxSkills.interactionUsability.humanCenteredApproaches',
              value
            )
          }
          {...errors['uxSkills.interactionUsability.humanCenteredApproaches']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Análise de pesquisa e benchmarking"
          tooltipDescription="É o estudo dos dados fornecidos em uma pesquisa ou de boas práticas realizadas por outras companhias."
          value={fields.uxSkills?.interactionUsability.benchmarkingAnalysis}
          onChange={(value) =>
            setField(
              'uxSkills.interactionUsability.benchmarkingAnalysis',
              value
            )
          }
          {...errors['uxSkills.interactionUsability.benchmarkingAnalysis']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Prototipação"
          tooltipDescription="Desenvolvimento ou criação de protótipos, modelos iniciais de alguma coisa que podem ser, posteriormente, usados como padrão."
          value={fields.uxSkills?.interactionUsability.prototyping}
          onChange={(value) =>
            setField('uxSkills.interactionUsability.prototyping', value)
          }
          {...errors['uxSkills.interactionUsability.prototyping']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Construção de wireframes"
          tooltipDescription="É a construção de um protótipo de baixa, média ou alta fidelidade - dependendo do quanto o modelo vai se assemelhar a versão final do produto ou serviço."
          value={fields.uxSkills?.interactionUsability.wireframeConstruction}
          onChange={(value) =>
            setField(
              'uxSkills.interactionUsability.wireframeConstruction',
              value
            )
          }
          {...errors['uxSkills.interactionUsability.wireframeConstruction']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Identificação de padrões e estrutura"
          tooltipDescription="É a garantia de consistência em produtos ou serviços."
          value={fields.uxSkills?.interactionUsability.patternIdentification}
          onChange={(value) =>
            setField(
              'uxSkills.interactionUsability.patternIdentification',
              value
            )
          }
          {...errors['uxSkills.interactionUsability.patternIdentification']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Arquitetura de informação"
          tooltipDescription="É a prática de decidir como organizar as partes de um sistema de forma que este seja compreensível."
          value={fields.uxSkills?.interactionUsability.informationArchitecture}
          onChange={(value) =>
            setField(
              'uxSkills.interactionUsability.informationArchitecture',
              value
            )
          }
          {...errors['uxSkills.interactionUsability.informationArchitecture']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Mapeamento de fluxo"
          tooltipDescription="É o desenho da estrutura ou dos passos que um usuário vai realizar para utilizar um produto ou serviço."
          value={fields.uxSkills?.interactionUsability.flowMapping}
          onChange={(value) =>
            setField('uxSkills.interactionUsability.flowMapping', value)
          }
          {...errors['uxSkills.interactionUsability.flowMapping']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Acessibilidade"
          tooltipDescription="É o fácil acesso a uma informação, produto ou serviço, a todas as pessoas, sejam elas com ou sem deficiências."
          value={fields.uxSkills?.interactionUsability.accessibility}
          onChange={(value) =>
            setField('uxSkills.interactionUsability.accessibility', value)
          }
          {...errors['uxSkills.interactionUsability.accessibility']}
        />
      </Col>
    </Row>
  );
};

export default InteractionUsability;
