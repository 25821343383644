import React from 'react';
import { globalTokens, Icon } from '@uxparaminaspretas/components';

import logo from 'assets/logo.svg';

import { Content, Logo, MenuButton } from './styles';

interface INavbarProps {
  mobile?: boolean;
  onMenuClick?: () => void;
}

const Navbar: React.FC<INavbarProps> = ({ mobile = false, onMenuClick }) => (
  <Content $mobile={mobile}>
    {onMenuClick && (
      <MenuButton onClick={onMenuClick}>
        <Icon size={30} icon="menu" color={globalTokens.colors.neutral5} />
      </MenuButton>
    )}
    <Logo src={logo} alt="Logo da plataforma" />
  </Content>
);

export default Navbar;
