import React from 'react';
import { Heading, Paragraph, Button } from '@uxparaminaspretas/components';

import error500Image from 'assets/error500.svg';
import AuthNavbar from 'components/AuthNavbar';

import { Main, TextContent } from './styles';

const Error500: React.FC = () => (
  <AuthNavbar>
    <Main>
      <img src={error500Image} />
      <TextContent>
        <Heading>Ops! Ocorreu um erro</Heading>
        <Paragraph>
          Ocorreu um erro ao acessar a página. Por favor, tente novamente.
        </Paragraph>
      </TextContent>
      <Button
        primary
        label="Atualizar página"
        onClick={() => window.location.reload()}
      />
    </Main>
  </AuthNavbar>
);

export default Error500;
