import styled from 'styled-components';
import { Paragraph, Subtitle } from '@uxparaminaspretas/components';

export const StyledSubtitle = styled(Subtitle)`
  font-weight: 400;
  margin-bottom: 8px;

  @media only screen and (max-width: 768px) {
    font-size: 14px;
    margin-bottom: 16px;
  }
`;

export const IconTitleContent = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
  margin-bottom: 24px;

  @media only screen and (max-width: 768px) {
    gap: 12px;

    && i {
      font-size: 25px;
    }

    & h1 {
      font-size: 20px;
    }
  }
`;

export const StepperContainer = styled.div`
  margin-top: 32px;
`;

export const StyledParagraph = styled(Paragraph)`
  @media only screen and (max-width: 768px) {
    font-size: 12px;
    line-height: 140%;
  }
`;
