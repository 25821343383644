import styled from 'styled-components';
import {
  Checkbox,
  Subtitle,
  Link,
  Caption
} from '@uxparaminaspretas/components';

export const DateContainer = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  gap: 24px;

  & > div {
    width: 124px;
  }

  input {
    width: 110%;
  }
`;

export const DateLabel = styled(Subtitle)`
  margin-bottom: 8px;
  margin-top: 0px;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-top: 8px;
`;

export const LinkNewCompany = styled(Link)`
  margin: 64px 0px 0px 0px;
`;

export const LinkDeleteCompany = styled(Link)`
  position: absolute;
  right: 0;
  top: 0;
`;

export const Error = styled(Caption)`
  margin-top: 8px;
  font-weight: 700;
  color: #c2463d;
  font-size: 12px;
  line-height: 16px;
`;
