import SkillLevel from './SkillLevel';
import UxSkills from './UxSkills';

class InteractionUsability {
  usabilityPrinciples?: SkillLevel;

  humanCenteredApproaches?: SkillLevel;

  benchmarkingAnalysis?: SkillLevel;

  prototyping?: SkillLevel;

  wireframeConstruction?: SkillLevel;

  patternIdentification?: SkillLevel;

  informationArchitecture?: SkillLevel;

  flowMapping?: SkillLevel;

  accessibility?: SkillLevel;

  static fromJson(data: any): InteractionUsability {
    const interaction = new InteractionUsability();
    interaction.usabilityPrinciples = UxSkills.setSkillLevel(
      data.principiosDeUsabilidade
    );
    interaction.humanCenteredApproaches = UxSkills.setSkillLevel(
      data.abordagensCentradasNoSerHumano
    );
    interaction.benchmarkingAnalysis = UxSkills.setSkillLevel(
      data.analiseDeBenchmarking
    );
    interaction.prototyping = UxSkills.setSkillLevel(data.prototipacao);
    interaction.wireframeConstruction = UxSkills.setSkillLevel(
      data.construcaoDeWireframes
    );
    interaction.patternIdentification = UxSkills.setSkillLevel(
      data.identificacaoDePadroes
    );
    interaction.informationArchitecture = UxSkills.setSkillLevel(
      data.arquiteturaDeInformacao
    );
    interaction.flowMapping = UxSkills.setSkillLevel(data.mapeamentoDeFluxo);
    interaction.accessibility = UxSkills.setSkillLevel(data.acessibilidade);

    return interaction;
  }
}

export default InteractionUsability;
