import React, { useState } from 'react';
import {
  Row,
  Col,
  Hidden,
  Textarea,
  Input,
  Select,
  RadioGroup,
  Radio
} from '@uxparaminaspretas/components';
import { useInterval } from 'usehooks-ts';

import Company, { JobType, UXMPHelp } from 'models/Company';
import User from 'models/User';

import {
  validateMonthEndDate,
  validateMonthStartDate
} from 'utils/validateMonthsDate';
import {
  DateContainer,
  DateLabel,
  StyledCheckbox,
  LinkDeleteCompany
} from './styles';

const jobTypeMap = [
  { name: 'Tempo integral', value: JobType.FULLTIME },
  { name: 'Meio período', value: JobType.PARTTIME },
  { name: 'Autônomo', value: JobType.AUTONOMOUS },
  { name: 'Freelancer', value: JobType.FREELANCER },
  { name: 'Temporário', value: JobType.TEMPORARY },
  { name: 'Estágio', value: JobType.INTERNSHIP },
  { name: 'Aprendiz', value: JobType.APPRENTICE },
  { name: 'Trainee', value: JobType.TRAINEE }
];

interface IProfessionalExperienceProps {
  fields: Partial<Company>;
  index: number;
  errors: Record<string, any>;
  onChange: (fields: Partial<User>) => void;
  onDelete: () => void;
}

const ProfessionalExperience: React.FC<IProfessionalExperienceProps> = ({
  fields: fieldsProps,
  index,
  onChange,
  errors,
  onDelete
}) => {
  const [fields, setFields] = useState(fieldsProps);

  useInterval(() => {
    onChange(fields);
  }, 1000);

  const setField = (name: keyof Company, value: any): void =>
    setFields({ ...fields, [name]: value });

  const setNumberField = (name: keyof Company, value: any): void =>
    setFields({
      ...fields,
      [name]: value.replace(/\D/g, '') ? Number(value.replace(/\D/g, '')) : ''
    });

  const startDateError =
    errors[`professionalExperience.companies[${index}].monthStartDate`]
      ?.error ||
    errors[`professionalExperience.companies[${index}].yearStartDate`]?.error;

  const endDateError =
    errors[`professionalExperience.companies[${index}].monthEndDate`]?.error ||
    errors[`professionalExperience.companies[${index}].yearEndDate`]?.error;

  // const validateMonthStartDate =
  //   fields.monthStartDate?.toString().padStart(2, '0') === '00'
  //     ? ''
  //     : fields.monthStartDate?.toString().padStart(2, '0');

  // const validateMonthEndDate =
  //   fields.monthEndDate?.toString().padStart(2, '0') === '00'
  //     ? ''
  //     : fields.monthEndDate?.toString().padStart(2, '0');

  return (
    <Row key={index}>
      <Col xs={12} md={6}>
        <Input
          name="name"
          label="Nome da empresa"
          placeholder="Nome da Empresa"
          value={fields.name}
          onChange={(e) => setField('name', e.target.value)}
          {...errors[`professionalExperience.companies[${index}].name`]}
        />
        <StyledCheckbox
          checked={fields.current}
          label="Trabalho atualmente nesta empresa"
          onChange={(e) => setField('current', e.target.checked)}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6}>
        <Input
          name="position"
          label="Cargo"
          placeholder="Cargo"
          value={fields.position}
          onChange={(e) => setField('position', e.target.value)}
          {...errors[`professionalExperience.companies[${index}].position`]}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6}>
        <Select
          name="jobType"
          options={jobTypeMap}
          placeholder="Selecione o Tipo de Emprego"
          label="Tipo de Emprego"
          labelKey="name"
          value={jobTypeMap.find((type) => type.value === fields.type)}
          onChange={({ option }) => setField('type', option.value)}
          {...errors[`professionalExperience.companies[${index}].type`]}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6} lg={6} xl={4}>
        <DateLabel>Data de início</DateLabel>
        <DateContainer>
          <Input
            name="monthStartDate"
            placeholder="Mês (ex. 04)"
            value={validateMonthStartDate(fields)}
            onChange={(e) => setNumberField('monthStartDate', e.target.value)}
            error={startDateError}
          />
          <Input
            name="yearStartDate"
            placeholder="Ano (ex. 2004)"
            value={fields.yearStartDate}
            onChange={(e) => setNumberField('yearStartDate', e.target.value)}
            error={startDateError}
          />
        </DateContainer>
      </Col>
      {!fields.current && (
        <Col xs={12} md={6} lg={6} xl={4}>
          <DateLabel>Data de término</DateLabel>
          <DateContainer>
            <Input
              name="monthEndDate"
              placeholder="Mês (ex. 04)"
              value={validateMonthEndDate(fields)}
              onChange={(e) => setNumberField('monthEndDate', e.target.value)}
              error={endDateError}
            />
            <Input
              name="yearEndDate"
              placeholder="Ano (ex. 2004)"
              value={fields.yearEndDate}
              onChange={(e) => setNumberField('yearEndDate', e.target.value)}
              error={endDateError}
            />
          </DateContainer>
        </Col>
      )}
      <Col xs={12}>
        <Textarea
          label="Faça um resumo sobre suas atividades (opcional)"
          placeholder="Conta pra gente um pouco sobre as atividades desempenhadas neste cargo"
          value={fields.activities}
          onChange={(e) => setField('activities', e.target.value)}
          {...errors[`professionalExperience.companies[${index}].activities`]}
        />
      </Col>
      <Col xs={12}>
        <RadioGroup
          label="Como a UXMP contribuiu para você ter conseguido essa oportunidade?"
          name="uxmpHelp"
          value={`${fields.uxmpHelp}`}
          onChange={(value) => setField('uxmpHelp', value)}
          {...errors[`professionalExperience.companies[${index}].uxmpHelp`]}
        >
          <Radio
            label="Fiquei sabendo da vaga através dos grupos da UXMP"
            value={UXMPHelp.FiqueiSabendo}
          />
          <Radio
            label="Fui indicada para a vaga por uma mulher da comunidade UXMP"
            value={UXMPHelp.Indicacao}
          />
          <Radio
            label="As trocas com as outras mulheres da comunidade me ajudaram a me preparar para a vaga"
            value={UXMPHelp.Comunidade}
          />
          <Radio
            label="Tive acesso a oportunidades de capacitação oferecidas pela uxmp (bolsas, cursos, workshops e eventos)"
            value={UXMPHelp.Capacitacao}
          />
        </RadioGroup>
      </Col>
      <LinkDeleteCompany
        onClick={onDelete}
        label="remover empresa"
        icon="delete"
      />
    </Row>
  );
};

export default ProfessionalExperience;
