export const validateMonthEndDate = (data: any): string => {
  if (data.monthEndDate?.toString().padStart(2, '0') === '00') {
    return '';
  }
  return data.monthEndDate?.toString().padStart(2, '0');
};

export const validateMonthStartDate = (data: any): string => {
  if (data.monthStartDate?.toString().padStart(2, '0') === '00') {
    return '';
  }
  return data.monthStartDate?.toString().padStart(2, '0');
};
