import * as yup from 'yup';
import { SubPage } from 'models/Page';
import { updateInteractionUsability } from 'services/uxSkills';
import InteractionUsability from './InteractionUsability';

const interactionUsabilitySubpage: SubPage = {
  title: 'Interação e usabilidade',
  path: '/interaction-usability',
  component: InteractionUsability,
  tooltip: {
    title: 'Interação e usabilidade',
    description:
      'Tem uma visão abrangente das partes que integram o produto e atua de modo a conectar a visão centrada no usuário aos requisitos de negócio e tecnologia. Contribui com product owners, engenheiros de software, analistas de dados, pesquisadores e outros designers na definição do produto, não apenas em sua execução.'
  },
  validationSchema: yup.object().shape({
    uxSkills: yup.object().shape({
      interactionUsability: yup.object().shape({
        usabilityPrinciples: yup.string().required(),
        humanCenteredApproaches: yup.string().required(),
        benchmarkingAnalysis: yup.string().required(),
        prototyping: yup.string().required(),
        wireframeConstruction: yup.string().required(),
        patternIdentification: yup.string().required(),
        informationArchitecture: yup.string().required(),
        flowMapping: yup.string().required(),
        accessibility: yup.string().required()
      })
    })
  }),
  action: updateInteractionUsability
};

export default interactionUsabilitySubpage;
