import styled from 'styled-components';
import { Heading, Paragraph } from '@uxparaminaspretas/components';

export const ForgotPasswordForm = styled.form`
  padding: 32px;
  display: flex;
  flex-direction: column;
  width: 780px;
  max-width: 100%;
  justify-content: center;
  flex: 1;
  overflow: auto;
  align-items: center;

  & button {
    width: 100%;
  }

  @media only screen and (max-width: 768px) {
    justify-content: flex-start;
    padding: 24px;
  }
`;

export const Title = styled(Heading)`
  text-align: center;
  margin-bottom: 60px;
`;

export const Description = styled(Paragraph)`
  text-align: center;
  margin-bottom: 60px;
`;

export const Fields = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 432px;
  max-width: 100%;

  & > div {
    margin-bottom: 8px;
  }
`;
