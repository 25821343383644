import styled from 'styled-components';
import { Paragraph } from '@uxparaminaspretas/components';

export const StyledParagraph = styled(Paragraph)`
  margin-bottom: 0px;
`;

export const ButtonContent = styled.div`
  width: 100%;
  text-align: right;
  margin-top: 14px;
`;

export const AvatarContent = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 0px 40px;
  text-align: center;
`;
