import * as yup from 'yup';
import { SubPage } from 'models/Page';
import { updateInitialData } from 'services/user';
import InitialData from './InitialData';

const initialDataSubpage: SubPage = {
  title: 'Dados iniciais',
  path: '/initial-data',
  component: InitialData,
  validationSchema: yup.object().shape({
    name: yup.string().required(),
    birthDate: yup.string().required(),
    email: yup.string().email().required(),
    cellphone: yup.string().required()
  }),
  action: updateInitialData
};

export default initialDataSubpage;
