import { useContext, useEffect, useState } from 'react';
import setValue from 'set-value';
import User from 'models/User';

import RegisterFormContext from './Context';
import IRegisterFormProps, {
  UserNestedObjectLeaves
} from './IRegisterFormProps';

interface IUseRegisterForm extends IRegisterFormProps {
  setField: (
    name: UserNestedObjectLeaves,
    value: string | number | boolean | Date | any
  ) => void;
  setArrayField: (
    name: UserNestedObjectLeaves,
    value: string | number | boolean | Date | any,
    index: number
  ) => void;
  setFields: (fields: Partial<{ [K in UserNestedObjectLeaves]: any }>) => void;
}

function useRegisterForm(): IUseRegisterForm {
  const context = useContext(RegisterFormContext);
  const [data, setData] = useState<IRegisterFormProps>(context!);
  const [fields, setFields] = useState(data.fields);

  useEffect(() => {
    setData(context!);
  }, [context?.errors]);

  const updateGlobalState = (newFields: Partial<User>): void => {
    if (data) {
      data.setFields(newFields as User);
    }
  };

  return {
    ...data,
    fields,
    setArrayField: (name, value, index) => {
      setFields((prevFields) => {
        const updateFields = { ...prevFields };
        setValue(updateFields, `${name}.${index}`, value);

        updateGlobalState(updateFields as User);

        return updateFields;
      });
    },
    setField: (name, value): void => {
      setFields((prevFields) => {
        const updateFields = { ...prevFields };
        setValue(updateFields, name, value);

        updateGlobalState(updateFields as User);

        return updateFields;
      });
    },
    setFields: (newFields): void => {
      setFields((prevFields) => {
        const updateFields = { ...prevFields };
        Object.keys(newFields).forEach((item) => {
          setValue(
            updateFields,
            item,
            newFields[item as UserNestedObjectLeaves]
          );
        });

        updateGlobalState(updateFields as User);

        return updateFields;
      });
    }
  };
}

export default useRegisterForm;
