class Address {
  city?: string;

  state?: string;

  complement?: string;

  number?: number;

  street?: string;

  zipCode?: string;

  static fromJson(data: any): Address {
    const address = new Address();
    address.city = data.cidade;
    address.state = data.estado;
    address.complement = data.complemento;
    address.number = data.numero;
    address.street = data.logradouro;
    address.zipCode = data.cep;

    return address;
  }

  static fromCepJson(data: any): Address {
    const address = new Address();
    address.city = data.localidade;
    address.state = data.uf;
    address.complement = data.complemento;
    address.street = data.logradouro;
    address.zipCode = data.cep;

    return address;
  }
}

export default Address;
