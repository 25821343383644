import React from 'react';

import AuthNavbar from 'components/AuthNavbar';
import {
  Button,
  Input,
  useAlert,
  Checker,
  CheckboxGroup
} from '@uxparaminaspretas/components';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import * as yup from 'yup';
import { signUp, login } from 'services/authenticate';
import useForm from 'hooks/useForm';
import UserSignUp from 'models/UserSignUp';
import {
  SignupForm,
  Title,
  SignupContainer,
  FormContainer,
  PasswordDisclaimers,
  LeftContainer,
  RightContainer,
  CheckboxContainer,
  PrivacyPolicyLink,
  CheckboxStyled,
  Fields,
  PrivacyPolicy,
  ActionsButtonSection
} from './styles';

const SignupSchema = yup.object().shape({
  fullName: yup.string().required(),
  document: yup.string().required().length(11),
  password: yup.string().required(),
  email: yup.string().email().required(),
  cellphone: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], 'As senhas devem ter o mesmo valor.'),
  newsletter: yup.boolean(),
  sms: yup.boolean(),
  whatsapp: yup.boolean(),
  telegram: yup.boolean(),
  privacy: yup
    .boolean()
    .required()
    .oneOf([true], 'É necessário aceitar os termos de privacidade.')
});

const passwordRules = (password: string): boolean[] => [
  password.length >= 8,
  /[A-Z]/.test(password),
  /[a-z]/.test(password),
  /\d/.test(password),
  /[^0-9a-zA-Z]/.test(password)
];

const SignUpComponent: React.FC = () => {
  const alert = useAlert();
  const history = useHistory();

  const { fields, setField, errors, handleSubmit, loading } =
    useForm<UserSignUp>(new UserSignUp(), SignupSchema, async (fields) => {
      const isPasswordValid = passwordRules(fields.password).every(
        (rule) => rule
      );

      if (!isPasswordValid) {
        return;
      }

      const result = await signUp(fields);

      if (result.data) {
        // history.push('/signin');
        alert.success('Conta criada com sucesso!');

        const resultLogin = await login(
          fields.document.replace(/[^\d]/g, ''),
          fields.password
        );

        if (resultLogin.data) {
          localStorage.setItem('@uxmp:token', resultLogin.data);
          history.push('/');
        }
      } else {
        alert.error('CPF já cadastrado');
      }
    });

  return (
    <AuthNavbar>
      <SignupForm
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <SignupContainer>
          <Title textAlign="center" level={1}>
            Criar conta
          </Title>
          <FormContainer>
            <LeftContainer>
              <Fields>
                <Input
                  name="fullName"
                  label="Nome Completo"
                  type="text"
                  value={fields.fullName}
                  placeholder="Seu nome"
                  onChange={(e) => setField('fullName', e.target.value)}
                  {...errors.fullName}
                />
                <Input
                  name="document"
                  label="CPF"
                  type="text"
                  placeholder="000.000.000-00"
                  onChange={(e) =>
                    setField('document', e.target.value.replace(/[^\d]/g, ''))
                  }
                  mask={[
                    { length: 3, regexp: /^[0-9]{1,3}$/ },
                    { fixed: '.' },
                    { length: 3, regexp: /^[0-9]{1,3}$/ },
                    { fixed: '.' },
                    { length: 3, regexp: /^[0-9]{1,3}$/ },
                    { fixed: '-' },
                    { length: 2, regexp: /^[0-9]{1,2}$/ }
                  ]}
                  {...errors.document}
                />
                <div>
                  <Input
                    name="password"
                    type="password"
                    label="Senha"
                    value={fields.password}
                    onChange={(e) => setField('password', e.target.value)}
                    {...errors.password}
                  />
                  <PasswordDisclaimers>
                    <Checker
                      messages={[
                        'Deve ter no mínimo 8 caracteres',
                        'Ao menos uma letra maiúscula',
                        'Ao menos uma letra minúscula',
                        'Ao menos um número',
                        'Ao menos um caractere especial'
                      ]}
                      rules={
                        fields.password ? passwordRules(fields.password) : []
                      }
                    />
                  </PasswordDisclaimers>
                </div>
                <Input
                  name="confirmPassword"
                  label="Confirmar senha"
                  type="password"
                  value={fields.confirmPassword}
                  onChange={(e) => setField('confirmPassword', e.target.value)}
                  {...errors.confirmPassword}
                />
              </Fields>
            </LeftContainer>
            <RightContainer>
              <Fields>
                <Input
                  label="E-mail"
                  name="email"
                  type="email"
                  value={fields.email}
                  placeholder="seuemail@dominio.com.br"
                  onChange={(e) => setField('email', e.target.value)}
                  {...errors.email}
                />
                <Input
                  name="cellphone"
                  label="Telefone de contato"
                  type="text"
                  placeholder="(__) _____-_____"
                  onChange={(e) =>
                    setField('cellphone', e.target.value.replace(/[^\d]/g, ''))
                  }
                  maxLength={16}
                  mask={[
                    { fixed: '(' },
                    { length: 2, regexp: /^[0-9]{1,2}$/ },
                    { fixed: ') ' },
                    { length: 5, regexp: /^[0-9]{1,5}$/ },
                    { fixed: '-' },
                    { length: 4, regexp: /^[0-9]{1,4}$/ }
                  ]}
                  {...errors.cellphone}
                />

                <CheckboxContainer>
                  <CheckboxStyled
                    name="newsletter"
                    onChange={(e) => setField('newsletter', e.target.checked)}
                    label="Aceito receber novidades e conteúdos por e-mail"
                  />
                  <CheckboxStyled
                    name="sms"
                    onChange={(e) => setField('sms', e.target.checked)}
                    label="Aceito receber novidades e conteúdos por SMS"
                  />
                  <CheckboxStyled
                    name="whatsapp"
                    onChange={(e) => setField('whatsapp', e.target.checked)}
                    label="Aceito receber novidades e conteúdos por Whatsapp"
                  />
                  <CheckboxGroup
                    name="privacyGroup"
                    value={fields.privacy ? ['privacy'] : []}
                    onChange={(value) => setField('privacy', !!value.length)}
                    {...errors.privacy}
                  >
                    <CheckboxStyled
                      name="privacy"
                      value="privacy"
                      label={
                        <PrivacyPolicy>
                          <span>Li e aceito a </span>
                          <PrivacyPolicyLink
                            href="/lgpd.pdf"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Política de Privacidade / LGPD
                          </PrivacyPolicyLink>{' '}
                          <span>do site</span>
                        </PrivacyPolicy>
                      }
                    />
                  </CheckboxGroup>
                </CheckboxContainer>
              </Fields>

              <ActionsButtonSection>
                <Button
                  primary
                  type="submit"
                  label="Criar conta"
                  loading={loading}
                />
                <RouterLink to="/signin">
                  <Button secondary label="Cancelar" />
                </RouterLink>
              </ActionsButtonSection>
            </RightContainer>
          </FormContainer>
        </SignupContainer>
      </SignupForm>
    </AuthNavbar>
  );
};

export default SignUpComponent;
