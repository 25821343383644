import React from 'react';
import { Row, Col } from '@uxparaminaspretas/components';

import { useRegisterForm } from 'provider/RegisterFormProvider';
import Graphic from 'components/Graphic';

const DigitalProduct: React.FC = () => {
  const { fields, errors, setField } = useRegisterForm();

  return (
    <Row>
      <Col xs={12}>
        <Graphic
          label="Design de produto digital"
          tooltipDescription="Se trata de entregar um produto ou serviço que permita que o usuário aproveite a tecnologia, sem que ele necessariamente precise entender como ela funciona."
          value={fields.uxSkills?.digitalProduct.design}
          onChange={(value) =>
            setField('uxSkills.digitalProduct.design', value)
          }
          {...errors['uxSkills.digitalProduct.design']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Documentação"
          tooltipDescription="É o registro todas as etapas e descobertas durante o processo por meio de arquivos, personas, jornadas, wireframes, dentre outros."
          value={fields.uxSkills?.digitalProduct.documentation}
          onChange={(value) =>
            setField('uxSkills.digitalProduct.documentation', value)
          }
          {...errors['uxSkills.digitalProduct.documentation']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Compreender limitações técnicas"
          tooltipDescription="Se trata da compreensão junto ao time quais são limitações técnicas que existem. Nem sempre as ideias de solução são tecnicamente viáveis."
          value={fields.uxSkills?.digitalProduct.understandTechnicalLimitations}
          onChange={(value) =>
            setField(
              'uxSkills.digitalProduct.understandTechnicalLimitations',
              value
            )
          }
          {...errors['uxSkills.digitalProduct.understandTechnicalLimitations']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Benchmark visual"
          tooltipDescription="Estudo de boas práticas realizadas por outras companhias no campo visual."
          value={fields.uxSkills?.digitalProduct.visualBenchmark}
          onChange={(value) =>
            setField('uxSkills.digitalProduct.visualBenchmark', value)
          }
          {...errors['uxSkills.digitalProduct.visualBenchmark']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Prototipação de baixa e alta fidelidade"
          tooltipDescription="É a criação de um modelo que servirá como base para a criação do produto ou serviço final. O nível baixo ou alto de fidelidade depende do quanto o modelo vai se aproximar do modelo final."
          value={fields.uxSkills?.digitalProduct.prototyping}
          onChange={(value) =>
            setField('uxSkills.digitalProduct.prototyping', value)
          }
          {...errors['uxSkills.digitalProduct.prototyping']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Animações e micro-interações"
          tooltipDescription="São pequenos momentos em que o usuário e o design interagem."
          value={fields.uxSkills?.digitalProduct.animations}
          onChange={(value) =>
            setField('uxSkills.digitalProduct.animations', value)
          }
          {...errors['uxSkills.digitalProduct.animations']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Design System / Atomic Design"
          tooltipDescription="É um conjunto de padrões de componentes de design elaborados a fim de manter a consistência visual e funcional de um certo produto, sistema ou organização."
          value={fields.uxSkills?.digitalProduct.designSystem}
          onChange={(value) =>
            setField('uxSkills.digitalProduct.designSystem', value)
          }
          {...errors['uxSkills.digitalProduct.designSystem']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Organização de workflow"
          tooltipDescription="É a organização do da sequência cronológica de atividades realizadas para a entrega de um produto ou serviço para o cliente final."
          value={fields.uxSkills?.digitalProduct.workflowOrganization}
          onChange={(value) =>
            setField('uxSkills.digitalProduct.workflowOrganization', value)
          }
          {...errors['uxSkills.digitalProduct.workflowOrganization']}
        />
      </Col>
    </Row>
  );
};

export default DigitalProduct;
