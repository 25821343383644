import User from 'models/User';
import { useContext, useEffect, useState } from 'react';

import RegisterFormContext from './Context';
import IRegisterFormProps from './IRegisterFormProps';

function useFields(): Partial<User> {
  const context = useContext(RegisterFormContext);
  const [data, setData] = useState<IRegisterFormProps>(context!);

  useEffect(() => {
    setData(context!);
  }, [context?.fields]);

  return data.fields;
}

export default useFields;
