import React from 'react';

import Navbar from 'components/Navbar';
import { Root } from './styles';

const AuthNavbar: React.FC = ({ children }) => (
  <>
    <Navbar />
    <Root>{children}</Root>
  </>
);

export default AuthNavbar;
