import { v4 as uuidv4 } from 'uuid';

export enum LanguageName {
  PORTUGUES = 'Portugues',
  INGLES = 'Ingles',
  ESPANHOL = 'Espanhol',
  ALEMAO = 'Alemao',
  FRANCES = 'Frances',
  ITALIANO = 'Italiano',
  JAPONES = 'Japones',
  LIBRAS = 'Libras',
  OUTRO = 'Outro'
}

export enum LanguageLevel {
  BASICO = 'Basico',
  INTERMEDIARIO = 'Intermediario',
  AVANCADO = 'Avancado',
  FLUENTE = 'Fluente',
  NATIVO = 'Nativo'
}

class Language {
  id: string;

  name?: LanguageName;

  level?: LanguageLevel;

  constructor() {
    this.id = uuidv4();
  }

  static fromJson(data: any): Language {
    const language = new Language();
    language.name = data.nome;
    language.level = data.nivel;

    return language;
  }
}

export default Language;
