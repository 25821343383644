import { v4 as uuidv4 } from 'uuid';

export enum UXMPHelp {
  FiqueiSabendo = 'FiqueiSabendo',
  Indicacao = 'Indicacao',
  Comunidade = 'Comunidade',
  Capacitacao = 'Capacitacao'
}

export enum JobType {
  FULLTIME = 'TempoIntegral',
  PARTTIME = 'MeioPeriodo',
  AUTONOMOUS = 'Autonomo',
  FREELANCER = 'Freelancer',
  TEMPORARY = 'Temporario',
  INTERNSHIP = 'Estagio',
  APPRENTICE = 'Aprendiz',
  TRAINEE = 'Trainee'
}

class Company {
  id: string;

  name?: string;

  current?: boolean;

  position?: string;

  type?: JobType;

  monthStartDate?: number;

  yearStartDate?: number;

  monthEndDate?: number;

  yearEndDate?: number;

  activities?: string;

  uxmpHelp?: string;

  // uxmpGroups?: boolean;

  // uxmpIndication?: boolean;

  constructor() {
    this.id = uuidv4();
  }

  static fromJson(data: any): Company {
    const company = new Company();
    company.name = data.nome;
    company.current = data.atual;
    company.position = data.cargo;
    company.type = data.tipo;
    company.activities = data.atividades;
    company.uxmpHelp = data.ajudaUXMP;
    // company.uxmpGroups = data.gruposUXMP;
    // company.uxmpIndication = data.indicacaoUXMP;

    const { month: monthStartDate, year: yearStartDate } = Company.readDate(
      data.dataInicio
    );
    company.monthStartDate = monthStartDate;
    company.yearStartDate = yearStartDate;

    if (!company.current) {
      const { month: monthEndDate, year: yearEndDate } = Company.readDate(
        data.dataTermino
      );
      company.monthEndDate = monthEndDate;
      company.yearEndDate = yearEndDate;
    }

    return company;
  }

  static readDate(date: string): { month: number; year: number } {
    const dateSplit = date.split('-');

    return { month: Number(dateSplit[1]), year: Number(dateSplit[2]) };
  }
}

export default Company;
