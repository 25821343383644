export const cpfMask = (value: string): string => {
  return value
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1.$2')
    .replace(/(\d{3})(\d)/, '$1-$2');
};

export const phoneMask = (value: string): string => {
  return value
    .replace(/(\d{1})(\d)/, '($1$2) ')
    .replace(/(\d{5})(\d)/, '$1-$2');
};

export const onlyNumbers = (value: string): string => {
  return value.replace(/\D/g, '');
};
