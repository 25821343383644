import React from 'react';
import { Row, Col } from '@uxparaminaspretas/components';

import { useRegisterForm } from 'provider/RegisterFormProvider';
import Graphic from 'components/Graphic';

const UxWriting: React.FC = () => {
  const { fields, errors, setField } = useRegisterForm();

  return (
    <Row>
      <Col xs={12}>
        <Graphic
          label="Comunicação"
          tooltipDescription="Entender o processo comunicacional (principalmente escrito). Manejar bem os recursos de comunicação e expressão, de compreender a linguagem (mensagem) a ser transmitida e do receptor."
          value={fields.uxSkills?.uxWriting.communication}
          onChange={(value) =>
            setField('uxSkills.uxWriting.communication', value)
          }
          {...errors['uxSkills.uxWriting.communication']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Redação"
          tooltipDescription="Ter uma boa leitura e escrita. Noções de redação publicitária podem ajudar."
          value={fields.uxSkills?.uxWriting.redaction}
          onChange={(value) => setField('uxSkills.uxWriting.redaction', value)}
          {...errors['uxSkills.uxWriting.redaction']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Microcopy"
          tooltipDescription="Microcopy são textos curtos e objetivos, bem escritos, que compõem a interface com alguma proposta de interação ou explicação, seja isso um place holder, um subtítulo explicativo ou label de botão, mas visando apelar para as esferas emocionais do usuário, criando um vínculo de alguma forma."
          value={fields.uxSkills?.uxWriting.microcopy}
          onChange={(value) => setField('uxSkills.uxWriting.microcopy', value)}
          {...errors['uxSkills.uxWriting.microcopy']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Usabilidade"
          tooltipDescription="Compreender os princípios básicos da usabilidade e conhecer problemas de uso intuitivo de interfaces."
          value={fields.uxSkills?.uxWriting.usability}
          onChange={(value) => setField('uxSkills.uxWriting.usability', value)}
          {...errors['uxSkills.uxWriting.usability']}
        />
      </Col>
      <Col xs={12}>
        <Graphic
          label="Taxonomia"
          tooltipDescription="Estruturação e organização da informação, fluxos informacionais e visualização da informação."
          value={fields.uxSkills?.uxWriting.taxonomy}
          onChange={(value) => setField('uxSkills.uxWriting.taxonomy', value)}
          {...errors['uxSkills.uxWriting.taxonomy']}
        />
      </Col>
    </Row>
  );
};

export default UxWriting;
