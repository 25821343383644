import React from 'react';
import {
  Row,
  Col,
  Hidden,
  Input,
  CheckboxGroup,
  Checkbox
} from '@uxparaminaspretas/components';

import { useRegisterForm } from 'provider/RegisterFormProvider';
import { SocialMediaGroups } from 'models/SocialMedia';

const SocialMedia: React.FC = () => {
  const { fields, setField, errors } = useRegisterForm();

  return (
    <Row>
      <Col xs={12} md={6}>
        <Input
          name="facebook"
          label="Facebook"
          required
          placeholder="https://www.facebook.com/"
          value={fields.socialMedia?.facebook}
          onChange={(e) => setField('socialMedia.facebook', e.target.value)}
          {...errors['socialMedia.facebook']}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6}>
        <Input
          name="instagram"
          label="Instagram"
          required
          placeholder="@instagram"
          value={fields.socialMedia?.instagram}
          onChange={(e) => setField('socialMedia.instagram', e.target.value)}
          {...errors['socialMedia.instagram']}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6}>
        <Input
          name="linkedin"
          label="Linkedin"
          required
          placeholder="https://www.linkedin.com/in/"
          value={fields.socialMedia?.linkedin}
          onChange={(e) => setField('socialMedia.linkedin', e.target.value)}
          {...errors['socialMedia.linkedin']}
        />
      </Col>
      <Col xs={12}>
        <CheckboxGroup
          label="De quais grupos da UXMP você gostaria de participar?"
          name="groups"
          value={fields.socialMedia?.groups}
          onChange={(value) => setField('socialMedia.groups', value)}
          {...errors['socialMedia.groups']}
        >
          <Checkbox
            label="Grupo do facebook"
            value={SocialMediaGroups.FACEBOOK}
          />
          <Checkbox label="Slack UXMP" value={SocialMediaGroups.SLACK} />
        </CheckboxGroup>
      </Col>
    </Row>
  );
};

export default SocialMedia;
