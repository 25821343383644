import React from 'react';
import { Row, Col, Avatar, Input, Hidden } from '@uxparaminaspretas/components';

import { useRegisterForm } from 'provider/RegisterFormProvider';
import convertBase64 from 'utils/convertBase64';

import { AvatarContent } from './styles';

const InitialData: React.FC = () => {
  const { fields, setField, setFields, errors } = useRegisterForm();

  const readImage = async (file?: File | null): Promise<void> => {
    if (!file) {
      return;
    }

    const fileContent = await convertBase64(file);

    setFields({
      'photo.content': fileContent,
      'photo.mime': file.type,
      'photo.name': file.name
    });
  };

  return (
    <Row>
      <Col xs={12}>
        <AvatarContent>
          <Avatar size="150px" src={fields.photo?.getPhoto()} />
          <Input
            type="file"
            name="name"
            label="Adicionar/alterar foto"
            id="upload-photo"
            accept="image/*"
            onChange={(e) => readImage(e.target.files?.item(0))}
          />
        </AvatarContent>
      </Col>
      <Col xs={12} md={6}>
        <Input
          name="name"
          label="Nome completo"
          value={fields.name}
          onChange={(e) => setField('name', e.target.value)}
          {...errors.name}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6}>
        <Input disabled name="CPF" label="CPF" value={fields.document} />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6}>
        <Input
          type="date"
          name="birthDate"
          label="Data de nascimento"
          value={fields.birthDate}
          onChange={(e) => setField('birthDate', e.target.value)}
          {...errors.birthDate}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6}>
        <Input
          name="email"
          label="E-mail"
          value={fields.email}
          onChange={(e) => setField('email', e.target.value)}
          {...errors.email}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
      <Col xs={12} md={6}>
        <Input
          name="phone"
          label="Telefone de contato"
          placeholder="(00) 00000-0000"
          value={fields.cellphone}
          onChange={(e) => setField('cellphone', e.target.value)}
          mask={[
            { fixed: '(' },
            { length: 2, regexp: /^[0-9]{1,2}$/ },
            { fixed: ') ' },
            { length: 5, regexp: /^[0-9]{1,5}$/ },
            { fixed: '-' },
            { length: 4, regexp: /^[0-9]{1,4}$/ }
          ]}
          {...errors.cellphone}
        />
      </Col>
      <Hidden xs sm>
        <Col xs={12} md={6} />
      </Hidden>
    </Row>
  );
};

export default InitialData;
