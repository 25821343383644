import * as yup from 'yup';
import Page from 'models/Page';
import { updatePersonalInformation } from 'services/user';
import PersonalInformation from './PersonalInformation';

const personalInformationPage: Page = {
  type: 'page',
  path: '/personal-information',
  description: 'Configuração do perfil',
  title: 'Informações pessoais',
  subtitle:
    'Agora que temos os seus dados de contato queremos te conhecer melhor, para isso preencha todos os campos abaixo.',
  icon: 'group',
  component: PersonalInformation,
  validationSchema: yup.object().shape({
    personalInformation: yup.object().shape({
      genre: yup.string().required(),
      selfDeclaration: yup.string().required(),
      maritalStatus: yup.string().required(),
      sons: yup.number().required(),
      financialContribution: yup.string().required(),
      hasDeficiency: yup.boolean().required(),
      deficiency: yup
        .array()
        .of(yup.string())
        .when('hasDeficiency', {
          is: (hasDeficiency: boolean) => hasDeficiency,
          then: yup.array().min(1)
        })
    })
  }),
  action: updatePersonalInformation
};

export default personalInformationPage;
